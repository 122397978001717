import api from './Api';

async function fetchFabrics() {
    try {
        const response = await api.get('/fabric/get/names');
        return response;
    } catch (error) {
        return error;
    }
}

export { fetchFabrics };
