import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(
        "[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-064089}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{14 September 2024}____[v3]_[0102]_MTcyNjI2ODQwMDAwMA==d2dc20599115660f72d4d9cc6565d610"
);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <App />
);
