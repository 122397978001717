import api from './Api';

export const freezeDraft = async (id, oeId, freezeData) => {
  try {
    let reqbody = {
      oeId: oeId,
      draftId: id,
      totalNoOfParts: freezeData.totalNumberParts,
      fusingParts: freezeData.FusingParts,
      handCuttingParts: freezeData.handCuttingParts,
      reCuttingParts: freezeData.reCuttingParts,
      pinning: freezeData.pinning,
    };
    const url = `/draft/freeze`;
    const response = await api.post(url, reqbody);
    return response;
  } catch (error) {
    return error;
  }
};


export const uploadFreezeDraftFiles = async (formData) => {
  try {
    const url = `/file/upload`;
    const response = await api.post(url, formData);
    return response;
  } catch (error) {
    return error;
  }
};