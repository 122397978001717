export const SUCCESS = {
    LOTADDED: "Lot Plan added successfully",
    LOTEDITED:"Lot Plan updated successfully",
    DRAFTADDED: "Draft Added Successfully",
    DRAFTFREEZED: "Draft Freeze Successfully",
    DRAFTEDITED: "",
    DRAFTFREEZED: "",
    FABRICADDED: "Fabric has been Successfully Allotted!",
    FILEUPLOADED: "File Uploaded Successfully",
    SUCCESS_SERV: "success",
    TABLE_ALLOTMENT_POST_SUCCESS:"Table allotment successfully.",
    TABLE_ALLOTMENT_DELETE_SUCCESS:"Deleted Successfully."
}

export const ERROR = {
    E500: "Internal Server Error. Please try again later.",
    E401: "Unauthorized. Please log in again.",
    E403: "Forbidden. You don't have permission to access this resource.",
    E404: "Data not found",
    NO_INTERNET: "No response received from the server. Please check your network connection.",
    UNEXPECTED: "An unexpected error occurred.Please try again.",
    ERROR_SERV : "error",
    TABLE_ALLOTMENT_POST_ERROR:"An error occurred while adding table allotment data.",
    TABLE_ALLOTMENT_ERROR_POST:"An error occurred while assigning table.",
    TABLE_ALLOTMENT_ERROR_TABLE_POST:"Error occurred while assigning table",
    TABLE_ALLOTMENT_POST_FAILED:"Failed to add table allotment data.",
    TABLE_ALLOTMENT_OT_FAILED:"To schedule more than 2 layers on a table either mark it as OT or change the date and try again!.",
}

export const WARNING = {
    LOTADDED: "Failed to add lot plan. Please try again",
    LOTEDITED: "",
    NO_LOT_FOUND: "No Lot Found. Please Add Atleast One Lot",
    UNSELECT_ORDER_STYLE: "Please select order number and style",
    BLANK_PREV_ROW: "Please fill out all fields in the previous row",
    REQUIRED_FIELD: "Please fill out all required fields correctly",
    WARNING_SERV: "warning",
    SAME_QUALITY: "Please change the fabric quality, as you cannot use the same quality for the same fabric",
    DRAFT_LIMIT_EXCEED: "You cannot add more than 6 drafts",
    QUANTITY_EXCEED: "Entered qty. does not match required qty",
    ATLEAST_ONE_LAYER: "Please Add Atleast one Layer",
    NO_DRAFT_FOUND: "No Draft Found. Please Add Atleast One Draft",
    MIN_ONE_DRAFT: "Please Freeze atleast one draft",
    NONPDF: "Please upload the file in DXF/MKR format.",
    PDF:"Please upload the file in PDF format.",
    NO_DRAFT_FREEZE: "Please freeze one draft to view cutting plan.",
    NOFILEAVL: "No File available to download. Please upload one",
    UNSAVE_CHANGES: "There are some unsaved changes. Please save the changes by clicking on the Add Layer/Save Changes button",
    TABLE_ALLOTMENT_LAYER_FIELD:"Please select the Layer",
    TABLE_ALLOTMENT_TABLE_FIELD:"Please select the Table",
    TABLE_ALLOTMENT_CONTRACTOR_FIELD:"Please select the Contractor Name",
    TABLE_ALLOTMENT_RECUTTING_FIELD:"Please select wheather the recutting is applied or not",
    TABLE_ALLOTMENT_DELETE_FAILED:"Failed to Delete the data",
    TABLE_ALLOTMENT_CHECKBOX_FAILED:"Already Mark as OT, you cannot Change",
    TABLE_ALLOTMENT_CAPACITY:"Table already has orders over its capacity",
    TABLE_ALLOTMENT_ALREADY_OT:"Table already has already an OT order",
    TABLE_ALLOTMENT_ALREADY_TWO_OT:"Table already has two orders or an OT order",
    MESSAGE_204: "Assigned Table has No data",
    NO_DATA_SELECTED_MONTH: "No data found for the selected month or as per data selected in filter.",
    INVALID_FILTER:"Please enter the date as per specified format."
}