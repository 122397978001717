
import api from "./Api";

async function findLayerPlan(oeId) {
  try {
    const response = await api.get(`/layerPlan/layers?oeId=${oeId}`);
    return response;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    return error;
  }
}

async function generateLayerPlan(oeId) {
  try {
    const response = await api.post(`/layerPlan/layers?oeId=${oeId}`);
    return response;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    return error;
  }
}

export { findLayerPlan, generateLayerPlan };
