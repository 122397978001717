import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import LotPlan from "./pages/lotPlan/LotPlan";
import CuttingPlan from "./pages/cuttingPlan/CuttingPlan";
import LayerPlan from "./pages/LayerPlan";
import FloorPlan from "./pages/FloorPlan";
import TicketSequencing from "./pages/TicketSequencing";
import CuttingTrack from "./pages/cuttingTrack/CuttingTrack";
import EditTrack from "./pages/cuttingTrack/EditTrack";
import TablePlan from "./pages/TablePlan";
import IssueAndReceive from "./pages/IssueAndReceive";
import Drafts from "./pages/cuttingPlan/Drafts";
import AddLotPlan from "./pages/lotPlan/AddLotPlan";
import Layout from "./Layout";
import RecieveOrders from "./pages/lotPlan/RecieveOrders";
import { GlobalProvider } from "./providers/GlobalProvider";
import AddDrafts from "./pages/cuttingPlan/AddDrafts";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { darkTheme, lightTheme } from "./ThemeContext";
import CompareDrafts from "./pages/cuttingPlan/CompareDraft";
import ViewCuttingTrack from "./components/specific/cuttingTrack/ViewCuttingTrack";

function App() {
  const [theme, setTheme] = useState(darkTheme);

  // const toggleTheme = () => {
  //   setTheme((prevTheme) => (prevTheme.palette.mode === 'light' ? darkTheme : lightTheme));
  // };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalProvider>
        <Router>
          <Layout
            child={
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/lot-plan" element={<LotPlan />} />
                <Route path="/cutting-plan" element={<CuttingPlan />} />
                <Route path="/layer-plan" element={<LayerPlan />} />
                {/* <Route path="/cutting-plan" element={<></>} /> */}
                <Route path="/floor-plan" element={<FloorPlan />} />
                <Route
                  path="/ticket-sequencing"
                  element={<TicketSequencing />}
                />
                <Route path="/cutting-track" element={<CuttingTrack />} />
                <Route
                  path="/cutting-track/edittrack"
                  element={<EditTrack />}
                />
                <Route path="/table-plan" element={<TablePlan />} />
                <Route
                  path="/issue-and-receive"
                  element={<IssueAndReceive />}
                />
                <Route
                  path="/lot-plan/receive-fabric"
                  element={<RecieveOrders open={true} />}
                />
                <Route
                  path="/cutting-track/view"
                  element={<ViewCuttingTrack />}
                />
                {/* <Route path="/receive-fabric" element={<SubOrder />} /> */}
                <Route path="/cutting-plan/drafts" element={<Drafts />} />
                {/* <Route path="/drafts" element={<></>} /> */}
                <Route path="/lot-plan/add" element={<AddLotPlan />} />
                <Route path="/lot-plan/edit" element={<AddLotPlan />} />
                <Route
                  path="/cutting-plan/drafts/add"
                  element={<AddDrafts />}
                />
                <Route
                  path="/cutting-plan/drafts/edit"
                  element={<AddDrafts />}
                />
                <Route
                  path="/cutting-plan/drafts/compare"
                  element={<CompareDrafts />}
                />
              </Routes>
            }
          />
        </Router>
      </GlobalProvider>
    </ThemeProvider>
  );
}

export default App;
