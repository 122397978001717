/* eslint-disable no-restricted-globals */
import React, { useCallback, useRef, useState } from "react";
import { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
// import "ag-grid-charts-enterprise";
import { Box, Grid, IconButton } from "@mui/material";
import TableBox from "../../../styles/common/TableBox";
import PrintIcon from '@mui/icons-material/Print';
import { exportToPDF } from "../../common/tables/PdfMaker";
import CustomButton from "../../common/buttons/CustomButton";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { freezeDraft } from "../../../services/FreezeDraft";
import CustomAlert from "../../common/CustomAlert";

const setPrinterFriendly = (api) => {
    // const eGridDiv = document.querySelector("#myGrid");
    // eGridDiv.style.width = "";
    // eGridDiv.style.height = "";
    api.sizeColumnsToFit();
    api.setGridOption("domLayout", "print");
};


const DraftCustomTable = ({ colDefs, drawerOpen, rowData, whichPage, onClick, setFreezed, freezed, sizeWithQty, pinnedBottomRowData }) => {
    const gridRef = useRef();
    const alertRef = useRef();
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true,
            floatingFilter: true,
            minWidth: 120,
            enablePivot: true,
            wrapHeaderText: true,
            resizable: true,
        }
    })
    const detailColumnDefs = useMemo(() => {
        if (!sizeWithQty) {
            return [];
        }
        const keys = Object.keys(sizeWithQty);
        return keys.map((key) => ({
            field: key,
            headerName: key,
        }));
    }, [sizeWithQty]);

    // const detailCellRendererParams = useMemo(() => {
    //     return {
    //         detailGridOptions: {
    //             columnDefs: detailColumnDefs,
    //             defaultColDef: {
    //                 flex: 1,
    //             },
    //         },
    //         getDetailRowData: (params) => {
    //             const size = params.data.size; 
    //             const remainingQuantity = sizeWithRemainingQuantity[size];
    //             const detailData = { size, remainingQuantity };
    //             params.successCallback([detailData]);
    //         },
    //     };
    // }, [detailColumnDefs]);

    const getDetailRowData = useCallback((params) => {
        params.successCallback([params.data.sizeWithRemainingQty]);
    }, []);

    // const onBtPrint = useCallback((gridRefs) => {
    //     setPrinterFriendly(gridRefs.current.api);
    //     setTimeout(() => {
    //         print();
    //     }, 2000);
    // }, [print]);

    const onBtPrint = useCallback(() => {
        print();
    }, []);

    const FreezeDraftTable = async () => {
        setFreezed(true);
    }

    const getRowStyle = useCallback((params) => {
        if (params.node.rowPinned) {
            return { background: 'white', fontWeight: 'bold', color: "black", pointerEvents: "none" };
        }
        if (params.node.rowIndex % 2 === 0) {
            return { background: '#475F77' };
        }
        return null;
    }, []);



    const detailRowHeight = 146;
    return (
        <TableBox drawerOpen={drawerOpen}>
            <CustomAlert ref={alertRef} />
            <Grid container spacing={1} style={{ padding: '10px', justifyContent: 'end' }} alignItems="center">
                <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto" sx={{
                    justifyContent: "end",
                    display: "flex",
                }}>
                    <IconButton onClick={() => onBtPrint(gridRef)}>
                        <PrintIcon />
                    </IconButton>
                </Grid>
                <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto" sx={{
                    justifyContent: "end",
                    display: "flex",
                }}>
                    <CustomButton variant="contained"
                        onClick={() => exportToPDF(gridRef.current.api)}>Export To PDF</CustomButton>
                </Grid>
                <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto" sx={{
                    justifyContent: "end",
                    display: "flex",
                }}>

                    {whichPage === "lotPlan" && <IconButton onClick={onClick}>
                        <EditNoteIcon />
                    </IconButton>}
                    {whichPage === "draftPlan" && !freezed && <CustomButton variant="contained" onClick={() => FreezeDraftTable()} disabled={rowData.length === 0} >Freeze</CustomButton>}
                </Grid>
            </Grid>
            <Box
                id="myGrid"
                className={
                    "ag-theme-quartz-dark "
                }
                style={{ width: "100%" }}
            >
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={colDefs}
                    defaultColDef={defaultColDef}
                    enableCharts={true}
                    enableRangeSelection={true}
                    pagination={true}
                    masterDetail={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 25, 50]}
                    detailRowHeight={detailRowHeight}
                    domLayout="autoHeight"
                    detailCellRendererParams={{
                        detailGridOptions: {
                            columnDefs: detailColumnDefs,
                            defaultColDef: { flex: 1 },
                        },
                        getDetailRowData: getDetailRowData,
                    }}
                    pinnedBottomRowData={pinnedBottomRowData}
                    getRowStyle={getRowStyle}
                />
            </Box>
        </TableBox>
    );
};
export default DraftCustomTable;