import { Box, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import DatePickerStatic from "../../components/common/calendars/DatePicker";
import CustomStaticDropdown from "../../components/common/dropdowns/CustomStaticDropdown";
import SelectDropdown from "../../components/common/dropdowns/DropDown";
import AgGridTable from "../../components/specific/cuttingTrack/CuttingTrackTable";
import CustomTextField from "../../components/common/textBoxs/CustomTextField";
import CustomButton from "../../components/common/buttons/CustomButton";
import ResponsiveDialog from "../../components/common/NoStyleModel";
import OrderDetailModel from "../../components/specific/cuttingTrack/OrderDetailModel";
import { useLocation } from "react-router-dom";
import {
  GetEditTrack,
  SubmitEditTrack,
} from "../../services/CuttingTrackServices";

export default function CuttingTrack() {
  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 200,
      floatingFilter: true,
    }),
    []
  );
  const [rowData, setRowData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [data, setData] = useState();
  const [bottomRowData, setBottomRowData] = useState([]);
  const location = useLocation();
  const Id = location.state.Id;
  const oeId = location.state.oeId;

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "Lot Details",

      children: [
        {
          field: "LotNumber",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
        },
        {
          field: "PlyLength",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
        },
        {
          field: "NumberofPly",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
          editable: true,
        },
      ],
      flex: 1,
    },
    {
      field: "Fabric Details",
      children: [
        {
          field: "FabricAvailable",
          flex: 1,
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          editable: true,
        },
        {
          field: "FabricUsed",
          flex: 1,
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          editable: true,
        },
        {
          field: "FabricReturn",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
          editable: true,
        },
        {
          field: "ThanNumber",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
          editable: true,
        },
        {
          field: "ThanMeter",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
          editable: true,
        },
        {
          field: "Balance ",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
          editable: true,
        },
        {
          field: "Remarks",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
          editable: true,
        },
      ],
    },
  ]);

  const TrackEdit = async (Id, oeId) => {
    try {
      const response = await GetEditTrack(Id, oeId);
      if (response.status === 200) {
        const ResponseData = response.data.response;
        setData(ResponseData);
        console.log("ResponseData", ResponseData);
        return ResponseData;
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (err) {
      console.log("GetError", err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await TrackEdit(Id, oeId);
        //particular layer data
        const layersizes =
          response.trackDetailsDTO.layerSizeCombinationForTickets;
        const sizeCombo = response.sizeCombination;
        const sizeComboArray = sizeCombo.split(/[\s,-]+/);
        console.log("sizeComboArray", sizeComboArray);

        let result = {
          field: "ticketSequencing",
          flex: 1,
          children: sizeComboArray.map((size) => ({
            field: size,
            children: [
              {
                headerName: "start",
                field: `${size}start`,
                flex: 1,
              },
              {
                headerName: "end",
                field: `${size}end`,
                flex: 1,
              },
            ],
          })),
        };
        setColumnDefs((prevCols) => [...prevCols, result]);
        let tempRowData = [];
        console.log("layersizes", layersizes);
        const arr = layersizes?.map((item) => {
          let tempObject = {};
          for (let i = 0; i < item.layerSizeCombinations.length; i++) {
            tempObject[`${item.layerSizeCombinations[i].size}start`] =
              item.layerSizeCombinations[i].start;
            tempObject[`${item.layerSizeCombinations[i].size}end`] =
              item.layerSizeCombinations[i].end;
          }
          return {
            LotNumber: item.lotNumber,
            PlyLength: item.plylength,
            NumberofPly: item.plyQuantity,
            FabricAvailable: item.fabricAvailable || null,
            FabricUsed: item.fabricUsed,
            FabricReturn: item.avlFabric - item.FabricUsed,
            ThanNumber: item.thanNumber,
            ThanMeter: item.thanMeter,
            Balance: item.balance,
            Remarks: item.remarks,
            ...tempObject,
          };
        });
        setRowData(arr);

        const totalData = {
          LotNumber: "Total",
          PlyLength: 40,
          NumberofPly: 50,
          FabricAvailable: 60,
        };
        setBottomRowData([totalData]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [Id, oeId]);

  const handleSubmit = () => {
    let tempData = [];
    for (let i = 0; i < rowData.length; i++) {
      let rowItem = rowData[i];
      let defaultRowItem =
        data.trackDetailsDTO.layerSizeCombinationForTickets[i];
      let layerSizeCombinations = [];
      for (let x = 0; x < defaultRowItem.layerSizeCombinations.length; x++) {
        const defaultCombination = defaultRowItem.layerSizeCombinations[x];
        layerSizeCombinations.push({
          id: defaultCombination.id,
          lotNumber: defaultCombination.lotNumber,
          start: rowItem[`${defaultCombination.size}start`],
          end: rowItem[`${defaultCombination.size}start`],
          size: defaultCombination.size,
        });
      }
      tempData.push({
        LotNumber: rowItem.LotNumber,
        NumberofPly: rowItem.NumberofPly,
        FabricUsed: rowItem.FabricUsed,
        ThanNumber: rowItem.ThanNumber,
        ThanMeter: rowItem.ThanMeter,
        Balance: rowItem.Balance,
        Remarks: rowItem.Remarks,
        lotId: data.trackDetailsDTO.layerSizeCombinationForTickets[i].lotId,
        plyQuantity:
          data.trackDetailsDTO.layerSizeCombinationForTickets[i].plyQuantity,
        layerSizeCombinations: layerSizeCombinations,
      });
    }

    const finalObject = {
      layerName: data.trackDetailsDTO.layerName,
      oeId: oeId,
      layerSizeCombinationForTickets: tempData,
    };

    SubmitEditTrack(Id, finalObject);
  };
  return (
    <>
      <Box>
        <OrderDetailModel
          data={data}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Box>
      <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
        <Grid container spacing={2} style={{ padding: "15px" }}></Grid>

        <AgGridTable
          colData={columnDefs}
          rowData={rowData}
          editable={true}
          defaultColDef={defaultColDef}
          pinnedBottomRowData={bottomRowData}
        />
        <Grid container style={{ paddingTop: "10px" }}>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <CustomButton variant="contained" onClick={handleSubmit}>
              Save Changes
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
