import * as React from 'react';
import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const CustomStaticDropdown=forwardRef((props, ref)=> {
    const { value, onChange, label,name, } = props;
  return (
   <>
  
        <FormControl fullWidth style={{minWidth:"120px"}}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
        labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          value={value}
          onChange={onChange}
          name={name}
          inputRef={ref}
          MenuProps={{ disableScrollLock: true }}
        >
          <MenuItem value={true}>Yes</MenuItem>
          <MenuItem value={false}>No</MenuItem>
        </Select>
        </FormControl>
        </>
  );
});

export default CustomStaticDropdown;


