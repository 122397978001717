import React, { useState } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuIcon from "@mui/icons-material/Menu";

const OrderText = styled(Typography)`
  margin-bottom: 8px !important;
  font-size: 14px !important;
`;

const OrderDrawer = styled(Box)`
  position: fixed;
  top: 67px;
  right: 0;
  width: 250px;
  height: 90%;
  border-left: 1px solid;
  //   border-radius: 5px;
  z-index: 1000;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  display: ${({ open }) => (open ? "block" : "none")};
  transition: "all 0.3s ease";
`;

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  marginRight: "15px",
  minHeight: "40px",
  top: "63px",
  position: "absolute",
  right: "-3px",
}));

const OrderDetailModel = ({ data, drawerOpen, setDrawerOpen }) => {
  const theme = useTheme();
  const [fabricExpanded, setFabricExpanded] = useState(false);
  const [demandedExpanded, setDemandedExpanded] = useState(false);
  const handleOrderModel = () => setDrawerOpen(true);
  const handleClose = () => setDrawerOpen(false);

  const handleFabricToggle = () => {
    setFabricExpanded(!fabricExpanded);
  };

  const handleDemandedToggle = () => {
    setDemandedExpanded(!demandedExpanded);
  };

  console.log("data" + data);
  return (
    <>
      <Container>
        <IconButton
          onClick={handleOrderModel}
          style={{
            display: drawerOpen || !data.orderNumber ? "none" : "block",
          }}
        >
          <MenuIcon
            style={{
              display: drawerOpen || !data.orderNumber ? "none" : "block",
            }}
          />
        </IconButton>
      </Container>
      <OrderDrawer open={drawerOpen} onClose={handleClose}>
        <Box borderRadius={2} style={{ height: "100%" }}>
          <IconButton
            sx={{ padding: "3px !important" }}
            style={{
              position: "absolute",
              top: "10px",
              right: "234px",
              border: "1px solid white",
              background: theme.palette.button.background,
              color: theme.palette.button.text,
            }}
            onClick={handleClose}
          >
            <ArrowForwardIosIcon sx={{ fontSize: "1.2rem !important" }} />
          </IconButton>
          <Box
            padding={2}
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              overflowX: "none",
              height: "calc(100% - 40px)",
              backgroundColor: "#364554",
            }}
          >
            <Typography align="center" fontSize="20px" paddingBottom="10px">
              Order Details
            </Typography>
            {data.orderNumber && (
              <OrderText>Order Number : {data.orderNumber}</OrderText>
            )}
            {data.buyerName && <OrderText>Buyer : {data.buyerName}</OrderText>}
            {data.merchantName && (
              <OrderText>Merchant Name : {data.merchantName}</OrderText>
            )}
            {data.style && <OrderText>Style : {data.style} </OrderText>}
            {data.color && <OrderText>Color : {data.color} </OrderText>}
            {data.fabricName && (
              <Box
                onClick={handleFabricToggle}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {fabricExpanded ? (
                  <ExpandLessIcon
                    style={{ fontWeight: "bold", marginBottom: "6px" }}
                  />
                ) : (
                  <ExpandMoreIcon
                    style={{ fontWeight: "bold", marginBottom: "6px" }}
                  />
                )}
                <OrderText style={{ fontWeight: "bold" }}>
                  Fabric Details
                </OrderText>
              </Box>
            )}
            {fabricExpanded && (
              <Box>
                {data.fabricName && (
                  <OrderText style={{ marginLeft: "24px" }}>
                    Fabric Name : {data.fabricName}
                  </OrderText>
                )}
                {data.fabricQuality && (
                  <OrderText style={{ marginLeft: "24px" }}>
                    Fabric Quality : {data.fabricQuality}
                  </OrderText>
                )}
                {data.fabricWidth != null && data.fabricWidth !== 0 && (
                  <OrderText style={{ marginLeft: "24px" }}>
                    Fabric Width : {data.fabricWidth}
                  </OrderText>
                )}
                {data.fabricInhouse && (
                  <OrderText style={{ marginLeft: "24px" }}>
                    Fabric Inhouse : {data.fabricInhouse}{" "}
                  </OrderText>
                )}
                {data.fabricWidthDetails && (
                  <Box>
                    {Object.entries(data.fabricWidthDetails).map(
                      ([fabInch, value]) => (
                        <OrderText key={fabInch} style={{ marginLeft: "24px" }}>
                          {fabInch} Fabric Width: {value}
                        </OrderText>
                      )
                    )}
                  </Box>
                )}
                {data.availableFabric != null && data.availableFabric !== 0 && (
                  <OrderText style={{ marginLeft: "24px" }}>
                    Available Fabric : {data.availableFabric}
                  </OrderText>
                )}
                {data.consumedFabric != null && data.consumedFabric !== 0 && (
                  <OrderText style={{ marginLeft: "24px" }}>
                    Consumed Fabric : {data.consumedFabric}
                  </OrderText>
                )}
                {data.remainingFabric != null && data.remainingFabric !== 0 && (
                  <OrderText style={{ marginLeft: "24px" }}>
                    Remaining Fabric : {data.remainingFabric}
                  </OrderText>
                )}

                <OrderText style={{ marginLeft: "24px" }}>
                  Fabric Use :
                </OrderText>

                <OrderText style={{ marginLeft: "24px" }}>
                  Fabric Needed :
                </OrderText>

                <OrderText style={{ marginLeft: "24px" }}>
                  Fabric Issued :
                </OrderText>

                <OrderText style={{ marginLeft: "24px" }}>
                  Issued Percentage :
                </OrderText>

                <OrderText style={{ marginLeft: "24px" }}>
                  Fabric Shortage:
                </OrderText>

                <OrderText style={{ marginLeft: "24px" }}>
                  Consumed Fabric :
                </OrderText>

                <OrderText style={{ marginLeft: "24px" }}>
                  Remaining Fabric:
                </OrderText>
              </Box>
            )}
            {data.sizeWithQty && (
              <Box
                onClick={handleDemandedToggle}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {demandedExpanded ? (
                  <ExpandLessIcon
                    style={{ fontWeight: "bold", marginBottom: "6px" }}
                  />
                ) : (
                  <ExpandMoreIcon
                    style={{ fontWeight: "bold", marginBottom: "6px" }}
                  />
                )}
                <OrderText style={{ fontWeight: "bold" }}>
                  Demanded Qty (Allowed Qty) : {data.sumOfDemandedQty} (
                  {data.sumOfAllowedQty})
                </OrderText>
              </Box>
            )}
            {demandedExpanded && (
              <Box>
                {Object.entries(data.sizeWithQty).map(([size, qty]) => (
                  <OrderText key={size} style={{ marginLeft: "24px" }}>
                    {size}: {qty} ({data.sizeWithAllowedQty[size]})
                  </OrderText>
                ))}
              </Box>
            )}
            {data.addOnQuantity != null && (
              <OrderText>Add-on Qunatity (%) : {data.addOnQuantity} </OrderText>
            )}
            {data.maxNoOfPly != null && data.maxNoOfPly !== 0 && (
              <OrderText>Maximum Number of Ply : {data.maxNoOfPly} </OrderText>
            )}
            {data.ppApproval && (
              <OrderText> PP Approval : {data.ppApproval}</OrderText>
            )}
            {data.cuttingStart && (
              <OrderText>Cutting Start : {data.cuttingStart} </OrderText>
            )}
            {data.cuttingEnd && (
              <OrderText>Cutting End : {data.cuttingEnd} </OrderText>
            )}
            {data.stitchingStart && (
              <OrderText>Stitching Start : {data.stitchingStart}</OrderText>
            )}
            {data.stitchingEnd && (
              <OrderText>Stitching End : {data.stitchingEnd}</OrderText>
            )}
            {data.deliveryDate && (
              <OrderText>Delivery Date : {data.deliveryDate} </OrderText>
            )}
            {data.noOfLots != null && data.noOfLots !== 0 && (
              <OrderText>Number of Lots : {data.noOfLots} </OrderText>
            )}
            {data.merchantAvg != null && data.merchantAvg !== 0 && (
              <OrderText>Merchant Average : {data.merchantAvg} </OrderText>
            )}
            {data.plannedAvg != null && data.plannedAvg !== 0 && (
              <OrderText>Planned Average : {data.plannedAvg} </OrderText>
            )}
            {data.totalNoOfParts != null && data.totalNoOfParts !== 0 && (
              <OrderText>
                Total Number of Parts: {data.totalNoOfParts}{" "}
              </OrderText>
            )}
            {data.fusingParts != null && data.fusingParts !== 0 && (
              <OrderText>Fusing Parts : {data.fusingParts} </OrderText>
            )}
            {data.handCuttingParts != null && data.handCuttingParts !== 0 && (
              <OrderText>
                Hand Cutting Parts : {data.handCuttingParts}{" "}
              </OrderText>
            )}
            {data.reCuttingParts != null && data.reCuttingParts !== 0 && (
              <OrderText>Re-cutting Parts : {data.reCuttingParts} </OrderText>
            )}
            {data.pinning != null && (
              <OrderText>Pinning : {data.pinning ? "Yes" : "No"} </OrderText>
            )}
            <Box>
              <Typography align="center" fontSize="20px" paddingBottom="20px">
                Order Image
              </Typography>
              <Box align="center">
                {data.orderImage ? (
                  <img
                    src={data.orderImage}
                    alt=""
                    style={{
                      width: "100%",
                      maxWidth: "200px",
                      maxHeight: "200px",
                    }}
                  />
                ) : (
                  <Box
                    style={{
                      width: "200px",
                      height: "200px",
                      backgroundColor: "grey",
                    }}
                  ></Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </OrderDrawer>
    </>
  );
};

export default OrderDetailModel;
