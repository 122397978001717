import api from './Api';

export const addfabric = async (requestBody) => {
    try {
        const response = await api.post("/fabric/upsert", requestBody);
        return response;
    } catch (error) {
        return error;
    }
};
