import { useTheme } from "@mui/material";
import Dropdown from "./Dropdown";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();
  const theme = useTheme();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  const toggleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => toggleDropdown()}
            style={{
              display: "flex",
              background: depthLevel > 0 ? theme.palette.header.background : undefined,
              color: depthLevel > 0 ? theme.palette.header.text : undefined,
              padding: depthLevel == 0 ? "2px" : undefined,
              borderBottom:
                depthLevel == 0 && dropdown ? "1px solid #000" : undefined,
              fontWeight: 600,
              textTransform: depthLevel == 0 ? "uppercase" : undefined,
            }}
          >
            <Link
              to={items.url}
              style={{
                flexGrow: 1,
                textTransform: depthLevel == 0 ? "uppercase" : undefined,
              }}
            >
              {items.title}
            </Link>
            {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            style={{ textTransform: depthLevel == 0 ? "uppercase" : undefined }}
          >
            {items.title}
            {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        <Link
          to={items.url}
          style={{
            background: depthLevel > 0 ? theme.palette.header.background : undefined,
            color: depthLevel > 0 ? theme.palette.header.text : undefined,
            fontWeight: 600,
            textTransform: depthLevel == 0 ? "uppercase" : undefined,
          }}
        >
          {items.title}
        </Link>
      )}
    </li>
  );
};

export default MenuItems;
