import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DownloadIcon from "@mui/icons-material/Download";
import CancelIcon from "@mui/icons-material/Cancel";
import AgGridTable from "./CuttingTrackTable";
import TableBox from "../../../styles/common/TableBox";
import styled from "styled-components";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./styles.css";
import { useScreenshot } from "use-react-screenshot";
import api from "../../../services/Api";

const DialogText = styled(Typography)`
  margin-bottom: 8px !important;
  font-size: 14px !important;
`;
const styleDetail = {
  borderRight: "2px solid white",
};

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();
const maxWidth = 800;

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const ViewCuttingTrack = (props) => {
  const [isloading, setIsloading] = useState(false);
  const [file, setFile] = useState("https://pdfobject.com/pdf/sample.pdf");
  const [containerWidth, setContainerWidth] = useState();
  const [numPages, setNumPages] = useState();
  const imageRef = useRef(null);
  const [image, setImage] = useState(null);
  const [screenshot, takeScreenshot] = useScreenshot();
  const [rowData, setRowData] = useState([]);
  const [data, setData] = useState(undefined);
  const [sizeLength, setSizeLength] = useState([]);

  const { open, handleClose } = props;

  const [columnDefs, setColDefs] = useState([
    {
      field: "Lotdetails",

      children: [
        { field: "LotNumber" },
        {
          field: "Plylength(m)",
        },
        {
          field: "Number of Ply",
        },
      ],
    },
    { field: "Ticket Sequencing" },
    {
      field: "Febric Details",
      children: [
        { field: "Fabric Avg." },
        {
          field: "Fabric Used",
        },
        {
          field: "Fabric Return",
        },
        {
          field: "Than Number",
        },
        {
          field: "Than Meter",
        },
        {
          field: "Balance",
        },
        {
          field: "Remarks",
        },
      ],
    },
  ]);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }
  const getViewData = async () => {
    try {
      setIsloading(true);
      const response = await api.get(`/cutting-track/view/${props?.orderId}`, {
        params: {
          oeId: props?.oeId,
        },
      });
      setIsloading(false);
      setSizeLength(response.data.response.sizeCombination.split("-"));
      setData(response.data.response);
      setRowData(
        response.data.response.trackDetailsDTO.layerSizeCombinationForTickets
      );
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    if (open) {
      getViewData();
    }
  }, [open]);

  useEffect(() => {
    console.log(imageRef.current);
  }, [imageRef.current]);

  useEffect(() => {
    setTimeout(() => {
      if (open && imageRef.current !== null) {
        if (imageRef.current) {
          let imageReference = imageRef.current;
          imageReference.style.background = "#364554";
          takeScreenshot(imageRef.current).then((img) => {
            if (img) {
              setImage(img);
            } else {
              console.error("Failed to capture screenshot");
            }
          });
        } else {
          console.error("imageRef.current is null");
        }
      }
    }, 200);
  }, [open, imageRef.current, rowData]);

  const handleDownload = async () => {
    const link = document.createElement("a");
    link.href = image;
    link.download = "downloaded-image.png"; // Specify the file name

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
  };

  return (
    <Dialog
      maxWidth=""
      onClose={handleClose}
      open={open}
      sx={{ overflow: "hidden" }}
    >
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography sx={{ fontSize: 27, fontWeight: 700 }}>
            Cutting Track
          </Typography>
          <Box sx={{ position: "absolute", right: 10 }}>
            <IconButton>
              <BorderColorIcon />
            </IconButton>
            <IconButton onClick={handleDownload}>
              <DownloadIcon />
            </IconButton>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isloading ? (
          <Box
            sx={{
              display: "flex",
              minWidth: "40vw",
              minHeight: "50vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Box
              ref={imageRef}
              sx={{
                border: "1px solid white",
                borderRadius: 2,
                overflowX: "scroll",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "auto",
                  justifyContent: "space-between",
                  width: imageRef?.current?.scrollWidth ?? 0,
                  minWidth: "100%",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "60%",
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                      Order Details
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 20,
                    }}
                  >
                    <Box style={{ paddingLeft: 10 }}>
                      <DialogText>Order Number: {data?.orderId}</DialogText>
                      <DialogText>Style: {data?.style}</DialogText>
                      <DialogText>Color: {data?.color}</DialogText>
                      <DialogText>Fabric Name: {data?.fabricName}</DialogText>
                      <DialogText>
                        Fabric Quality: {data?.fabricQuality}
                      </DialogText>
                    </Box>
                    <Box sx={styleDetail} />
                    <Box>
                      <DialogText>
                        Table Number: {data?.tableMaster?.tableNumber}
                      </DialogText>
                      <DialogText>Layer Number: {data?.layerNumber}</DialogText>
                      <DialogText>
                        Size Combination: {data?.sizeCombination}
                      </DialogText>
                      <DialogText>Ply Quantity: {data?.plyQty}</DialogText>
                      <DialogText>Layer Width: </DialogText>
                    </Box>
                    <Box sx={styleDetail} />
                    <Box>
                      <DialogText>Layer Length:</DialogText>
                      <DialogText>
                        Marker Number: {data?.markerNumber}
                      </DialogText>
                      <DialogText>
                        Re-cutting Applicable: {data?.reCuttingApplied}
                      </DialogText>
                      <DialogText>Re-cutting Start Date:</DialogText>
                      <DialogText>Re-cutting End Date:</DialogText>
                    </Box>
                    <Box sx={styleDetail} />
                    <Box>
                      <DialogText>
                        Re-cutting Contractor Name: {data?.contractorName}
                      </DialogText>
                      <DialogText>No of Parts:</DialogText>
                      <DialogText>No of Panel Cutting:</DialogText>
                    </Box>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderLeft: "2px solid white",
                    width: "25%",
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                      Contractor Details & other Details
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box style={{ padding: 10 }}>
                      <DialogText>
                        Contractor Name: {data?.contractorName}
                      </DialogText>
                      <DialogText>Contractor ID: {}</DialogText>
                      <DialogText>Generator Date:</DialogText>
                      <DialogText>Fabric Inhouse:</DialogText>
                      <DialogText>PP Approval: {data?.ppApproval}</DialogText>
                    </Box>
                    <Box sx={styleDetail} />
                    <Box style={{ padding: 10 }}>
                      <DialogText>
                        Cutting Start: {data?.cuttingStartDate}
                      </DialogText>
                      <DialogText>
                        Cutting End: {data?.cuttingEndDate}
                      </DialogText>
                      <DialogText>
                        Stitching Start: {data?.stitchingStartDate}
                      </DialogText>
                      <DialogText>
                        Stitching End: {data?.stitchingEndDate}
                      </DialogText>
                      <DialogText>
                        Delivery Date: {data?.deliveryDate}
                      </DialogText>
                    </Box>
                  </Box>
                </Box>

                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderLeft: "2px solid white",
                    width: "15%",
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      height: "50px",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      borderBottom: "2px solid white",
                      paddingRight: 10,
                    }}
                  >
                    <Typography sx={{}}>original copy</Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 10,
                    }}
                  >
                    <Box>
                      <Typography>HOD Signature</Typography>
                      <Typography>Contractor Signature</Typography>
                      <Typography
                        sx={{
                          color: "#364554",
                          backgroundColor: "white",
                          padding: "5px",
                        }}
                      >
                        Cutting Track
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* <Box sx={{ display: "flex" }}>
              <AgGridTable
                pagination={false}
                page={"viewpage"}
                colData={columnDefs}
                rowData={[]}
              />
            </Box> */}
              <Box id="div">
                <table className="data-table" id="example">
                  <thead>
                    <tr>
                      <th style={{ padding: "10px" }} colspan="3">
                        Lot Details
                      </th>
                      <th
                        style={{ padding: "10px" }}
                        colspan={sizeLength.length * 2}
                      >
                        Ticket Sequencing
                      </th>
                      <th style={{ padding: "10px" }} colspan="7">
                        Fabric details
                      </th>
                    </tr>
                    <tr>
                      <th rowSpan={2} style={{ padding: "10px" }}>
                        lot number
                      </th>
                      <th rowSpan={2} style={{ padding: "10px" }}>
                        {"ply length (M)"}
                      </th>
                      <th rowSpan={2} style={{ padding: "10px" }}>
                        Number of Ply
                      </th>
                      {sizeLength.map((size, key) => (
                        <th colSpan={2} key={key}>
                          {size}
                        </th>
                      ))}

                      <th rowSpan={2} style={{ padding: "10px" }}>
                        fabric ava.
                      </th>
                      <th rowSpan={2} style={{ padding: "10px" }}>
                        fabric used
                      </th>
                      <th rowSpan={2} style={{ padding: "10px" }}>
                        fabric return
                      </th>
                      <th rowSpan={2} style={{ padding: "10px" }}>
                        than number
                      </th>
                      <th rowSpan={2} style={{ padding: "10px" }}>
                        than meter
                      </th>
                      <th rowSpan={2} style={{ padding: "10px" }}>
                        Balance
                      </th>
                      <th rowSpan={2} style={{ padding: "10px" }}>
                        remark
                      </th>
                    </tr>
                    <tr>
                      {sizeLength.map((size, key) => (
                        <>
                          <th key={key}>start</th>
                          <th key={key}>end</th>
                        </>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rowData.map((value, key) => (
                      <tr key={key}>
                        <td>{value.lotNumber}</td>
                        <td>1</td>
                        <td>{value.plyQuantity}</td>
                        {value.layerSizeCombinations.map((sizeValue) => (
                          <>
                            <td>{sizeValue.start}</td>
                            <td>{sizeValue.end}</td>
                          </>
                        ))}
                        <td>1</td>
                        <td>{value.fabricUsed}</td>
                        <td>1</td>
                        <td>{value.thanNumber}</td>
                        <td>{value.thanMeter}</td>
                        <td>{value.balance}</td>
                        <td>{value.remarks}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                border: "1px solid white",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
              >
                <Page key={`page_${0}`} pageNumber={1} />
              </Document>
              {/* <Typography>No Marker Photo available</Typography> */}
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ViewCuttingTrack;
