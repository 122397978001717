import React, { useState } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuIcon from "@mui/icons-material/Menu";

const OrderText = styled(Typography)`
  margin-bottom: 8px !important;
  font-size: 14px !important;
`;

const OrderDrawer = styled(Box)`
  position: fixed;
  top: 67px;
  right: 0;
  width: 250px;
  height: 90%;
  border-left: 1px solid;
  //   border-radius: 5px;
  z-index: 1000;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  display: ${({ open }) => (open ? "block" : "none")};
  transition: "all 0.3s ease";
`;

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  marginRight: "15px",
  minHeight: "40px",
  top: "63px",
  position: "absolute",
  right: "-3px",
}));

const OrderDetailModel = ({ drawerOpen, setDrawerOpen, data }) => {
  const theme = useTheme();
  const [fabricExpanded, setFabricExpanded] = useState(false);
  const [demandedExpanded, setDemandedExpanded] = useState(false);
  const [remainingExpanded, setRemainingExpanded] = useState(false);

  const handleOrderModel = () => setDrawerOpen(true);
  const handleClose = () => setDrawerOpen(false);

  const handleFabricToggle = () => {
    setFabricExpanded(!fabricExpanded);
  };

  const handleDemandedToggle = () => {
    setDemandedExpanded(!demandedExpanded);
  };
  const handleRemainingToggle = () => {
    setRemainingExpanded(!remainingExpanded);
  };

  return (
    <>
      <Container>
        <IconButton
          onClick={handleOrderModel}
          style={{
            display: drawerOpen,
          }}
        >
          <MenuIcon
            style={{
              display: drawerOpen,
            }}
          />
        </IconButton>
      </Container>
      <OrderDrawer open={drawerOpen} onClose={handleClose}>
        <Box borderRadius={2} style={{ height: "100%" }}>
          <IconButton
            sx={{ padding: "3px !important" }}
            style={{
              position: "absolute",
              top: "10px",
              right: "234px",
              border: "1px solid white",
              background: theme.palette.button.background,
              color: theme.palette.button.text,
            }}
            onClick={handleClose}
          >
            <ArrowForwardIosIcon sx={{ fontSize: "1.2rem !important" }} />
          </IconButton>
          <Box
            padding={2}
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              overflowX: "none",
              height: "calc(100% - 40px)",
              backgroundColor: "#364554",
            }}
          >
            <Typography align="center" fontSize="20px" paddingBottom="10px">
              Order Details
            </Typography>
            <OrderText>Order Number : {data?.orderId}</OrderText>
            <OrderText>Buyer : {data?.buyerName}</OrderText>
            <OrderText>Merchant Name :{data?.merchantName}</OrderText>
            <OrderText>Style :{data?.style} </OrderText>
            <OrderText>Color :{data?.color} </OrderText>
            <Box
              onClick={handleFabricToggle}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              {fabricExpanded ? (
                <ExpandLessIcon
                  style={{ fontWeight: "bold", marginBottom: "6px" }}
                />
              ) : (
                <ExpandMoreIcon
                  style={{ fontWeight: "bold", marginBottom: "6px" }}
                />
              )}
              <OrderText style={{ fontWeight: "bold" }}>
                Fabric Details
              </OrderText>
            </Box>
            {fabricExpanded && (
              <Box>
                <OrderText style={{ marginLeft: "24px" }}>
                  Fabric Name :{data.fabricName}
                </OrderText>
                <OrderText style={{ marginLeft: "24px" }}>
                  Fabric Quality :{data.fabricQuality}
                </OrderText>
                <OrderText style={{ marginLeft: "24px" }}>
                  Fabric Width :{data.fabricWidth}
                </OrderText>
                <OrderText style={{ marginLeft: "24px" }}>
                  Fabric Inhouse :
                </OrderText>
                <Box>
                  <OrderText style={{ marginLeft: "24px" }}>
                    Fabric Width:
                  </OrderText>
                </Box>
                <OrderText style={{ marginLeft: "24px" }}>
                  Available Fabric :{data.avlFabric}
                </OrderText>

                <OrderText style={{ marginLeft: "24px" }}>
                  Consumed Fabric :{data.consumedFabric}
                </OrderText>

                <OrderText style={{ marginLeft: "24px" }}>
                  Remaining Fabric :
                </OrderText>
              </Box>
            )}
            <Box
              onClick={handleDemandedToggle}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              {demandedExpanded ? (
                <ExpandLessIcon
                  style={{ fontWeight: "bold", marginBottom: "6px" }}
                />
              ) : (
                <ExpandMoreIcon
                  style={{ fontWeight: "bold", marginBottom: "6px" }}
                />
              )}
              <OrderText style={{ fontWeight: "bold" }}>
                Demanded Qty (Allowed Qty) :
              </OrderText>
            </Box>

            {demandedExpanded && (
              <Box>
                <OrderText style={{ marginLeft: "24px" }}></OrderText>
              </Box>
            )}

            <Box
              onClick={handleRemainingToggle}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              {remainingExpanded ? (
                <ExpandLessIcon
                  style={{ fontWeight: "bold", marginBottom: "6px" }}
                />
              ) : (
                <ExpandMoreIcon
                  style={{ fontWeight: "bold", marginBottom: "6px" }}
                />
              )}
              <OrderText style={{ fontWeight: "bold" }}>
                Remaining Qty (Cut Qty) :{data?.cuttingQuantity}
              </OrderText>
            </Box>

            <OrderText>Add-on Qunatity (%) : </OrderText>

            <OrderText>Maximum Number of Ply :{data?.maxNoOfPly}</OrderText>

            <OrderText> PP Approval :{data?.ppApproval}</OrderText>

            <OrderText>Cutting Start :{data?.cuttingStartDate} </OrderText>

            <OrderText>Cutting End : {data?.cuttingEndDate}</OrderText>

            <OrderText>Stitching Start :{data?.stitchingStartDate} </OrderText>

            <OrderText>Stitching End :{data?.stitchingEndDate}</OrderText>

            <OrderText>Delivery Date : {data?.deliveryDate}</OrderText>

            <OrderText>Merchant Average :{data?.merchantAvg} </OrderText>

            <OrderText>Planned Average :{data?.plannedAvg} </OrderText>

            <OrderText>Total Number of Parts:{data?.totalNoOfParts}</OrderText>

            <OrderText>Fusing Parts : {data?.fusingParts}</OrderText>

            <OrderText>Hand Cutting Parts :{data?.handCuttingParts}</OrderText>

            <OrderText>Re-cutting Parts : {data?.reCuttingParts}</OrderText>

            <OrderText>Pinning :{data?.pinning} </OrderText>

            <Box>
              <Typography align="center" fontSize="20px" paddingBottom="20px">
                Order Image
              </Typography>
              <Box align="center">
                <img
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    maxHeight: "200px",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </OrderDrawer>
    </>
  );
};

export default OrderDetailModel;
