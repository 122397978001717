import api from "./Api";

async function addLotAPI(lotData) {
  try {
    const response = await api.post("/lot/upsert", lotData);
    return response;
  } catch (error) {
    return error;
  }
}

export { addLotAPI };
