import React from "react";
import Header from "./components/navbar/Header";

const Layout = ({ child }) => {
  const [open, setOpen] = React.useState(true);

  return (
    <div className="App">
      <Header />
      {child}
    </div>
  );
};

export default Layout;
