import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

export default function BreadCrumbs() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/");
  function handleClick(event) {
    event.preventDefault();
    if (event.target.innerHTML !== "/") {
      if (event.target.href) {
        navigate(event.target.href.replace(event.target.origin, ""));
      }
    }
  }

  function getText(text) {
    let textFormatted = text.replace("-", " ");
    textFormatted =
      textFormatted.charAt(0).toUpperCase() + textFormatted.slice(1);
    if (textFormatted.length > 0) return textFormatted;
    return "Dashboard";
  }
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        {path.map((item, index) => {
          if (index == 0) {
            return (
              <Link
                underline="hover"
                color="inherit"
                href="/dashboard"
                key={index}
              >
                Home
              </Link>
            );
          }
          if (index == path.length - 1) {
            return (
              // <Link
              //   underline="hover"
              //   color="text.primary"
              //   href={path.slice(0, index + 1).join("/")}
              //   aria-current="page"
              //   key={index}
              // >
              //   {getText(item)}
              // </Link>
              <Typography
                color="text.primary"
                key={index}
              >
                {getText(item)}
              </Typography>
            );
          }
          return (
            <Link
              underline="hover"
              color="inherit"
              href={path.slice(0, index + 1).join("/")}
              key={index}
            >
              {getText(item)}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
