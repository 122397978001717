import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ResponsiveDialog from "../../components/common/NoStyleModel";
import { fetchOrders } from "../../services/OrderListServices";
import { viewLotData } from "../../services/ViewLotServices";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import sortOrderIds from "../../components/common/SortOrders";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CustomButton from "../../components/common/buttons/CustomButton";
import CustomAutoComplete from "../../components/common/dropdowns/CustomAutoComplete";
import CustomAlert from "../../components/common/CustomAlert";
import CustomTextField from "../../components/common/textBoxs/CustomTextField";
import OrderDetailModel from "../../components/specific/lotplan/OrderDetailsModel";
import * as ENUMTEXT from "../../constants/AlertText";
import { addLotAPI } from "../../services/AddLotServices";
import { Directions } from "@mui/icons-material";
import AddLayerConfirmation from "../../components/specific/draft/AddLayerConfirmation ";

function checkNull(data) {
  for (let item of data) {
    for (let key in item) {
      if (item[key] === null || item[key] === undefined || item[key] === "") {
        return false;
      }
    }
  }
  return true;
}
export default function AddLotPlan() {
  const theme = useTheme();
  const alertRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { orderInformation, autoSelectLotId } = location.state || {};
  const initialDataLoaded = useRef(false);
  const [orderData, setOrderData] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  const [total, setTotal] = useState(0);
  const [Needed, setNeeded] = useState(1345);
  const [FabricExcess, setFabricExcess] = useState(false);
  const [addLotConfirmationPopUp, setAddLotConfirmationPopUp] = useState(false);
  const orderInfoRef = useRef({
    OrderNumberValue: null,
    buyerName: "",
    merchantName: "",
    optionsStyle: [],
    Stylevalue: null,
    fabricName: "",
    fabricQuality: "",
    maxNoPly: "",
  });
  const OrderNumberInputValue = useRef();
  const StyleInputValue = useRef();
  const [orderInfo, setOrderInfo] = useState(orderInfoRef.current);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rows, setRows] = useState([
    { id: 1, lotNumber: "", fabricMeter: "", fabricWidth: "", defect: "" },
  ]);
  const [MaximumPly, setMaximumPly] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [orderDetailData, setOrderDetailData] = useState({});
  const [errors, setErrors] = useState({});

  const handleOrderChange = useCallback((event, newValue) => {
    orderInfoRef.current.OrderNumberValue = newValue;
    orderInfoRef.current.Stylevalue = null;
    setOrderInfo({ ...orderInfoRef.current });
  }, []);

  const handleStyleChange = useCallback((event, newValue) => {
    orderInfoRef.current.Stylevalue = newValue;
    setOrderInfo({ ...orderInfoRef.current });
  }, []);

  useEffect(() => {
    const loadOrders = async () => {
      try {
        const orderAllData = await fetchOrders();
        if (orderAllData.status === 200) {
          setOrderData(orderAllData.data.response);
          const orderIds = Object.keys(orderAllData.data.response.orders);
          const sortedOrderIds = sortOrderIds(orderIds);
          setOrderOptions(sortedOrderIds);
        } else {
          alertRef.current.showAlert(orderAllData, ENUMTEXT.ERROR.ERROR_SERV);
        }
      } catch (error) {
        console.error(error);
      }
    };
    loadOrders();
  }, []);

  useEffect(() => {
    if (
      orderInfo.OrderNumberValue !== undefined &&
      Object.keys(orderData?.orders ?? {}).length > 0
    )
      if (orderInfo.OrderNumberValue) {
        const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
        if (selectedOrder) {
          orderInfoRef.current.buyerName = selectedOrder[0]?.buyer || "";
          orderInfoRef.current.merchantName = selectedOrder[0]?.merchant || "";
          orderInfoRef.current.optionsStyle = selectedOrder.map((order) => ({
            label: `${order.style ?? ""} ${order.color ?? ""} ${
              order.size ?? ""
            } ${order.remarks ?? ""}`,
            oeid: order.oeId ?? "",
            sizeWithQty: order.sizeWithQty,
            totalQty: order.totalQty,
          }));

          if (orderInfoRef.current.optionsStyle.length === 1) {
            orderInfoRef.current.Stylevalue =
              orderInfoRef.current.optionsStyle[0];
          }
          setOrderInfo({ ...orderInfoRef.current });
          setOrderDetailData({
            orderNumber: orderInfoRef.current.OrderNumberValue,
            buyerName: orderInfoRef.current.buyerName,
            merchantName: orderInfoRef.current.merchantName,
            orderImage: selectedOrder[0]?.imagePath || "",
          });
          setDrawerOpen(true);
        }
      } else {
        setOrderInfo((prev) => ({
          ...prev,
          buyerName: "",
          merchantName: "",
          optionsStyle: [],
        }));
      }
  }, [orderInfo.OrderNumberValue, orderData]);

  useEffect(() => {
    if (autoSelectLotId !== undefined) {
      console.log(autoSelectLotId);
      handleOrderChange(undefined, autoSelectLotId);
    }
  }, []);

  useEffect(() => {
    const fetchLotData = async () => {
      if (
        !initialDataLoaded.current &&
        orderInformation?.OrderNumberValue &&
        orderInformation?.Stylevalue
      ) {
        orderInfoRef.current.OrderNumberValue =
          orderInformation.OrderNumberValue;
        orderInfoRef.current.Stylevalue = orderInformation.Stylevalue;
        orderInfoRef.current.buyerName = orderInformation.buyerName;
        orderInfoRef.current.fabricName = orderInformation.fabricName;
        orderInfoRef.current.fabricQuality = orderInformation.fabricQuality;
        orderInfoRef.current.maxNoPly = orderInformation.maxNoPly;
        orderInfoRef.current.merchantName = orderInformation.merchantName;
        orderInfoRef.current.optionsStyle = orderInformation.optionsStyle;
        setOrderInfo({ ...orderInfoRef.current });
        initialDataLoaded.current = true;
      }
      try {
        const orderId = orderInfo.OrderNumberValue;
        const oeId = orderInfo.Stylevalue?.oeid;
        if (!orderId || !oeId) return;

        const response = await viewLotData(Number(orderId), oeId);
        if (response.status === 200) {
          const { lots } = response.data.response;
          if (!lots) {
            setRows([
              {
                id: 1,
                lotNumber: "",
                fabricMeter: "",
                fabricWidth: "",
                defect: "",
              },
            ]);
          } else {
            setRows(
              lots?.map((lot, index) => ({
                id: index + 1,
                lotNumber: lot.lotNumber,
                fabricMeter: lot.mtr,
                fabricWidth: lot.width,
                defect: lot.defect,
              }))
            );
            let total = 0;
            for (let i = 0; i < lots.length; i++) {
              const lotmeter = lots[i].mtr;
              total += lotmeter;
            }
            setTotal(total);
          }
        } else {
          alertRef.current.showAlert(response, ENUMTEXT.ERROR.ERROR_SERV);
        }
      } catch (error) {
        console.error("Error fetching lot data:", error);
      }
    };
    fetchLotData();
  }, [orderInfo.OrderNumberValue, orderInfo.Stylevalue]);

  useEffect(() => {
    if (
      orderInfo.Stylevalue !== undefined &&
      orderInfo.OrderNumberValue !== undefined &&
      Object.keys(orderData?.orders ?? {}).length > 0
    ) {
      if (orderInfo.Stylevalue && orderInfo.OrderNumberValue) {
        const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
        if (selectedOrder) {
          const orderDetail = selectedOrder.find(
            (order) => order.oeId === orderInfo.Stylevalue.oeid
          );
          if (orderDetail) {
            if (!orderDetail.fabAvl) {
              setDialogOpen(true);
            } else {
              orderInfoRef.current.fabricName = orderDetail.fabricName;
              orderInfoRef.current.fabricQuality = orderDetail.fabricQuality;
              setOrderInfo({ ...orderInfoRef.current });
              setMaximumPly(orderDetail.maxNoOfPly);
              setOrderDetailData((prevData) => ({
                ...prevData,
                style: orderDetail.style,
                color: orderDetail.color,
                fabricName: orderDetail.fabricName,
                fabricQuality: orderDetail.fabricQuality,
                fabricWidth: orderDetail.fabricWidth,
                availableFabric: orderDetail.avlFabric,
                consumedFabric: orderDetail.consumedFabric,
                remainingFabric: orderDetail.remainingFabric,
                maxNoOfPly: orderDetail.maxNoOfPly,
                fabricInhouse: orderDetail.fabInHouse,
                ppApproval: orderDetail.ppApproval,
                cuttingStart: orderDetail.cuttingStartDate,
                cuttingEnd: orderDetail.cuttingEndDate,
                stitchingStart: orderDetail.stitchingStartDate,
                stitchingEnd: orderDetail.stitichingEndDate,
                deliveryDate: orderDetail.deliveryDate,
                sizeWithQty: orderDetail.sizeWithQty,
                sumOfDemandedQty: orderDetail.sumOfDemandedQty,
                noOfLots: orderDetail.noOfLots,
                merchantAvg: orderDetail.merchantAvg,
                plannedAvg: orderDetail.plannedAvg,
                sumOfAllowedQty: orderDetail.sumOfAllowedQty,
                sizeWithAllowedQty: orderDetail.sizeWithAllowedQty,
                fabricWidthDetails: orderDetail.fabricWidthDetails,
                addOnQuantity: orderDetail.addOnQuantity,
                totalNoOfParts: orderDetail.totalNoOfParts,
                fusingParts: orderDetail.fusingParts,
                handCuttingParts: orderDetail.handCuttingParts,
                reCuttingParts: orderDetail.reCuttingParts,
                pinning: orderDetail.pinning,
              }));
            }
          }
        }
      } else {
        setRows([
          {
            id: 1,
            lotNumber: "",
            fabricMeter: "",
            fabricWidth: "",
            defect: "",
          },
        ]);
        setMaximumPly("");
      }
    }
  }, [orderInfo.Stylevalue, orderData, orderInfo.OrderNumberValue]);

  const handleMaximumPlyInputChange = (event) => {
    setMaximumPly(event.target.value);
  };

  const handleAddRow = () => {
    if (orderInfo.OrderNumberValue === null || StyleInputValue == "") {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.UNSELECT_ORDER_STYLE,
        ENUMTEXT.WARNING.WARNING_SERV
      );
    } else {
      const lastRow = rows[rows.length - 1];
      if (checkNull([lastRow])) {
        const newRow = {
          id: rows.length + 1,
          lotNumber: "",
          fabricMeter: "",
          fabricWidth: "",
          defect: "",
        };
        setRows([...rows, newRow]);
      } else {
        alertRef.current.showAlert(
          ENUMTEXT.WARNING.BLANK_PREV_ROW,
          ENUMTEXT.WARNING.WARNING_SERV
        );
      }
    }
  };

  const handleDeleteRow = (rowId) => {
    function removeAndUpdateIds(array, idToRemove) {
      const filteredArray = array.filter((obj) => obj.id !== idToRemove);
      for (let i = 0; i < filteredArray.length; i++) {
        filteredArray[i].id = i + 1;
      }
      const targetArray = array.filter((obj) => obj.id === idToRemove);

      const fabricmeter = targetArray[0].fabricMeter;

      const newTotal = total - fabricmeter;

      setTotal(newTotal);
      return filteredArray;
    }
    const updatedArray = removeAndUpdateIds(rows, rowId);
    setRows(updatedArray);
  };

  const submitLot = async () => {
    setAddLotConfirmationPopUp(false);

    if (
      !orderInfo.OrderNumberValue ||
      !orderInfo.Stylevalue ||
      !checkNull(rows)
    ) {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.REQUIRED_FIELD,
        ENUMTEXT.WARNING.WARNING_SERV
      );
      return;
    }
    try {
      const newLot = {
        orderId: orderInfo.OrderNumberValue,
        oeId: orderInfo.Stylevalue.oeid,
        maxPlyOnTable: MaximumPly,
        // FabricIssued: total,
        // FabricRemaining:,
        // IssuedPercent:,
        lotEssentialsDtos: rows.map((row) => ({
          lotNumber: row.lotNumber,
          fabricLength: row.fabricMeter,
          fabricWidth: row.fabricWidth,
          defect: row.defect,
        })),
      };
      const response = await addLotAPI(newLot);
      if (response.status === 200) {
        location.pathname === "/lot-plan/edit"
          ? alertRef.current.showAlert(
              ENUMTEXT.SUCCESS.LOTEDITED,
              ENUMTEXT.SUCCESS.SUCCESS_SERV
            )
          : alertRef.current.showAlert(
              ENUMTEXT.SUCCESS.LOTADDED,
              ENUMTEXT.SUCCESS.SUCCESS_SERV
            );
        setTimeout(() => navigate("/lot-plan"), 200);
      } else {
        alertRef.current.showAlert(response, ENUMTEXT.ERROR.ERROR_SERV);
      }
    } catch (error) {
      console.error(error);
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.LOTADDED,
        ENUMTEXT.ERROR.ERROR_SERV
      );
    }
  };

  const handleSave = async () => {
    if (total < Needed) {
      setFabricExcess(true);
      setAddLotConfirmationPopUp(true);
    } else if (total > Needed) {
      setAddLotConfirmationPopUp(true);
    } else {
      submitLot();
    }
  };

  const validateLotNumber = (id, value) => {
    const isDuplicate = rows.some(
      (row) => row.id !== id && row.lotNumber.trim() === value.trim()
    );

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (isDuplicate) {
        newErrors[id] = true;
      } else {
        delete newErrors[id];
      }

      return newErrors;
    });
  };

  const formHasErrors = Object.keys(errors).length > 0;

  return (
    <>
      <CustomAlert ref={alertRef} />
      <Box>
        <OrderDetailModel
          data={orderDetailData}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Box>
      <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
        <Grid container spacing={1} style={{ padding: "5px" }}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.OrderNumberValue}
              onChange={handleOrderChange}
              options={orderOptions}
              label="Select Order Number"
              ref={OrderNumberInputValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.Stylevalue}
              onChange={handleStyleChange}
              options={orderInfo.optionsStyle}
              label="Select Style Details"
              ref={StyleInputValue}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            sx={{
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            <ResponsiveDialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
            />
            <CustomTextField
              value={MaximumPly}
              onChange={handleMaximumPlyInputChange}
              type="number"
              label="Maximum Ply On Table"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Box style={{ display: "flex", gap: "35px" }}>
              <Box>
                <Typography>Needed:116000</Typography>
                <Typography>Issued: {total}</Typography>

                <Typography>Remaining:{116000 - total}</Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography>Issued(%):</Typography>
                <Typography>{((total / 116000) * 100).toFixed(2)}%</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          px: 1,
          marginBottom: "20px",
          width: drawerOpen ? `calc(100% - 250px)` : "100%",
        }}
      >
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          {rows.map((row, index) => (
            <>
              <Grid item xs={12} lg={3} xl={3} key={row.id}>
                <CustomTextField
                  key={row.id}
                  value={row.lotNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    setRows(
                      rows.map((r) =>
                        r.id === row.id ? { ...r, lotNumber: value } : r
                      )
                    );
                    validateLotNumber(row.id, value);
                  }}
                  type="text"
                  label="Lot Number"
                  error={!!errors[row.id]}
                />
              </Grid>
              {/* <Grid item xs={12} lg={3} xl={3}>
                <CustomTextField
                  key={row.id}
                  value={row.fabricMeter}
                  onChange={(e) =>
                    setRows(
                      rows.map((r) =>
                        r.id === row.id
                          ? { ...r, fabricMeter: e.target.value }
                          : r
                      )
                    )
                  }
                  type="number"
                  label="Fabric Meter"
                />
              </Grid> */}
              <Grid item xs={12} lg={3} xl={3}>
                <CustomTextField
                  key={row.id}
                  value={row.fabricMeter}
                  onChange={(e) => {
                    const newFabricMeter = e.target.value;
                    // Update rows with the new fabric meter value
                    const updatedRows = rows.map((r) =>
                      r.id === row.id
                        ? { ...r, fabricMeter: newFabricMeter }
                        : r
                    );

                    // Calculate the new total
                    const newTotal = updatedRows.reduce(
                      (sum, r) => sum + parseFloat(r.fabricMeter || 0),
                      0
                    );

                    // Update both rows and total states
                    setRows(updatedRows);
                    setTotal(newTotal);
                  }}
                  type="number"
                  label="Fabric Meter"
                />
              </Grid>

              <Grid item xs={12} lg={2.5} xl={2.5}>
                <CustomTextField
                  key={row.id}
                  value={row.fabricWidth}
                  onChange={(e) =>
                    setRows(
                      rows.map((r) =>
                        r.id === row.id
                          ? { ...r, fabricWidth: e.target.value }
                          : r
                      )
                    )
                  }
                  type="number"
                  label="Fabric Width"
                />
              </Grid>
              <Grid item xs={12} lg={2.5} xl={2.5}>
                <CustomTextField
                  key={row.id}
                  value={row.defect}
                  onChange={(e) =>
                    setRows(
                      rows.map((r) =>
                        r.id === row.id ? { ...r, defect: e.target.value } : r
                      )
                    )
                  }
                  type="text"
                  label="Defect"
                />
              </Grid>
              <Grid item xs={1}>
                {index == 0 && index !== rows.length - 1 && (
                  <Box sx={{ minHeight: "40px" }}></Box>
                )}
                {index !== 0 && (
                  <IconButton onClick={() => handleDeleteRow(row.id)}>
                    <DeleteIcon
                      sx={{ color: theme.palette.general.background }}
                    ></DeleteIcon>
                  </IconButton>
                )}
                {index === rows.length - 1 && (
                  <IconButton onClick={() => handleAddRow()}>
                    <AddCircleIcon
                      style={{ color: theme.palette.general.background }}
                    />
                  </IconButton>
                )}
              </Grid>
            </>
          ))}
        </Grid>

        <Grid container style={{ paddingTop: "10px" }}>
          <Grid item xs={12}>
            <CustomButton
              variant="contained"
              onClick={handleSave}
              disabled={formHasErrors}
            >
              {location.pathname === "/lot-plan/edit"
                ? "Save Changes"
                : "Submit"}
            </CustomButton>
          </Grid>
        </Grid>
        <AddLayerConfirmation
          open={addLotConfirmationPopUp}
          handleConfirm={submitLot}
          handleUpdateLot={() => {
            setAddLotConfirmationPopUp(false);
          }}
          confirmText={"Submit Lot"}
          updateText={"Add Fabric"}
          Dialogtitle={"Fabric Issue"}
          Dialogtext={
            FabricExcess ? (
              <p>
                Fabric issued is less than the fabric needed. Please add more
                fabric, as this may impact the quantity.
              </p>
            ) : (
              <p>Fabric Issued is more then the Fabric Needed </p>
            )
          }
        />
      </Box>
    </>
  );
}
