/* eslint-disable no-restricted-globals */
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
// import "ag-grid-charts-enterprise";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import TableBox from "../../../styles/common/TableBox";
import PrintIcon from "@mui/icons-material/Print";
import { exportToPDF } from "./PdfMaker";
import CustomButton from "../buttons/CustomButton";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DatePickerViews from "../calendars/MonthYearCalendar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

// const setPrinterFriendly = (api) => {

//     api.sizeColumnsToFit();
//     api.setGridOption("domLayout", "print");
// };

const CustomTable = forwardRef(
  (
    {
      colDefs,
      drawerOpen,
      rowData,
      whichPage,
      onClick,
      pinnedBottomRowData,
      selectedDate,
      setSelectedDate,
      getTableValue,
      onGridReady,
      whichTable,
      setDrawerOpen,
    },
    ref
  ) => {
    const gridRef = useRef();
    {
      console.log("whichPage", whichPage);
    }
    const defaultColDef = useMemo(() => {
      return {
        flex: 1,
        filter: true,
        floatingFilter: true,
        minWidth: 120,
        wrapHeaderText: true,
        resizable: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
      };
    });
    // const onBtPrint = useCallback((gridRefs) => {
    //     setPrinterFriendly(gridRefs.current.api);
    //     setTimeout(() => {
    //         print();
    //     }, 2000);
    // }, [print]);

    const onBtPrint = useCallback(() => {
      print();
    }, []);

    const onBtDelete = useCallback((params) => {
      let deletedRow = params.node.data;
      gridRef.current.api.applyTransaction({ remove: [deletedRow] });
    }, []);

    useImperativeHandle(ref, () => ({
      onBtDelete,
    }));

    const getRowStyle = useCallback(
      (params) => {
        if (params.node.data?.ot) {
          return { background: "blue" };
        }
        if (params.node.rowPinned) {
          return {
            background: "white",
            fontWeight: "bold",
            color: "black",
            border: "0.5px groove #454545",
            pointerEvents: "none",
          };
        }
        if (
          params.node.rowIndex % 2 &&
          whichPage !== ("TableSch" && "TableSchOther")
        ) {
          return { background: "#475F77" };
        }

        return null;
      },
      [whichPage]
    );
    const autoGroupColumnDef = useMemo(() => {
      return {
        minWidth: 200,
      };
    }, []);

    const sideBar = useMemo(() => {
      return {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
        ],
        defaultToolPanel: "none",
      };
    }, []);

    const gridStyle = {
      width: "100%",
      height: whichPage === "TablePlan" ? "auto" : undefined, // Ensure height is managed by maxHeight
      maxHeight: whichPage === "TablePlan" ? "75vh" : undefined, // Set your desired max height here
      overflow: whichPage === "TablePlan" ? "auto" : undefined, // Add overflow auto to handle scrolling if needed
    };

    const handleOrderModel = () => setDrawerOpen(true);

    return (
      <TableBox drawerOpen={drawerOpen}>
        <Grid
          container
          spacing={1}
          style={{
            padding: "10px",
            justifyContent:
              whichPage === "TableSch" || whichPage === "TableSchOther"
                ? "start"
                : "end",
          }}
          alignItems="center"
        >
          <Grid
            item
            xs="auto"
            sm="auto"
            md="auto"
            lg="auto"
            xl="auto"
            sx={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            {whichPage == "TableSch" && (
              <Typography>{getTableValue}</Typography>
            )}
            {whichPage == "TableSchOther" && (
              <Typography>Other Table</Typography>
            )}
            {/* <Typography> {whichPage === "TableSch" ? getTableValue: whichPage === "TableSchOther" ? "Other Table" : ""} </Typography> */}
          </Grid>
          <Grid
            item
            xs="auto"
            sm="auto"
            md="auto"
            lg="auto"
            xl="auto"
            sx={{
              justifyContent: "end",
              display: "flex",
            }}
          >
            {whichPage === "TablePlan" && (
              <DatePickerViews
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              ></DatePickerViews>
            )}
          </Grid>
          {whichPage === "TablePlan" && (
            <Grid
              item
              xs="auto"
              sm="auto"
              md="auto"
              lg="auto"
              xl="auto"
              sx={{
                justifyContent: "end",
                display: "flex",
              }}
            >
              <IconButton onClick={handleOrderModel}>
                <FilterAltIcon />
              </IconButton>
            </Grid>
          )}
          <Grid
            item
            xs="auto"
            sm="auto"
            md="auto"
            lg="auto"
            xl="auto"
            sx={{
              justifyContent: "end",
              display: "flex",
            }}
          >
            {whichPage !== "TableSch" && whichPage !== "TableSchOther" && (
              <IconButton onClick={() => onBtPrint(gridRef)}>
                <PrintIcon />
              </IconButton>
            )}
          </Grid>
          <Grid
            item
            xs="auto"
            sm="auto"
            md="auto"
            lg="auto"
            xl="auto"
            sx={{
              justifyContent: "end",
              display: "flex",
            }}
          >
            {whichPage !== "TableSch" && whichPage !== "TableSchOther" && (
              <CustomButton
                variant="contained"
                onClick={() => exportToPDF(gridRef.current.api)}
              >
                Export To PDF
              </CustomButton>
            )}
          </Grid>
          <Grid
            item
            xs="auto"
            sm="auto"
            md="auto"
            lg="auto"
            xl="auto"
            sx={{
              justifyContent: "end",
              display: "flex",
            }}
          >
            {whichPage === "lotPlan" && (
              <IconButton onClick={onClick}>
                <EditNoteIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Box id="myGrid" className={"ag-theme-quartz-dark"} style={gridStyle}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            enableCharts={true}
            enableRangeSelection={true}
            pagination={whichPage === "TablePlan" ? false : true}
            paginationPageSize={
              whichPage === "TableSch"
                ? 5
                : whichPage === "TableSchOther"
                ? 5
                : 10
            }
            paginationPageSizeSelector={[5, 10, 25, 50]}
            domLayout={whichPage === "TablePlan" ? "print" : "autoHeight"}
            pinnedBottomRowData={pinnedBottomRowData}
            getRowStyle={getRowStyle}
            autoGroupColumnDef={autoGroupColumnDef}
            sideBar={sideBar}
            rowModelType={whichPage === "FloorPlan" ? "serverSide" : undefined}
            onGridReady={onGridReady}
            serverSideSortAll={true}
            serverSideFilter={true}
            serverSidePagination={true}
            suppressRowTransform={true}
          />
        </Box>
      </TableBox>
    );
  }
);
export default CustomTable;
