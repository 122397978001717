// src/themes.js
import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#ffffff',
      paper: '#f8f9fa',
    },
    text: {
      primary: '#000000',
    },
    header: {
      background: '#f8f9fa',
      text: '#000000',
    },
    button: {
      background: '#4b49ac',
      text: 'white',
    },
    general: {
      background: '#4b49ac'
    }
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#364554',
      paper: '#364554',
    },
    text: {
      primary: '#ffffff',
    },
    header: {
      background: '#2a3642',
      text: '#ffffff',
    },
    button: {
      background: '#ffffff',
      text: '#364554',
    },
    general: {
      background: 'white'
    }
  },
});

export { lightTheme, darkTheme };
