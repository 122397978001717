import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
  Box,
  IconButton,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import CancelIcon from "@mui/icons-material/Cancel";
import * as ENUMTEXT from "../../../constants/AlertText";
import CustomAutoComplete from "../../common/dropdowns/CustomAutoComplete";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomButton from "../../common/buttons/CustomButton";
import CustomAlert from "../../common/CustomAlert";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  marginRight: "15px",
  minHeight: "40px",
  top: "63px",
  position: "absolute",
  right: "-3px",
}));

const OrderDrawer = styled(Box)`
  position: fixed;
  top: 67px;
  right: 0;
  width: 280px;
  height: 90%;
  border-left: 1px solid;
  z-index: 1000; /* Ensure it's on top of other components */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  display: ${({ open }) => (open ? "block" : "none")};
  transition: "all 0.3s ease";
  background-color: #364554;
  overflow-y: auto;
`;
const TableAllotmentFilter = ({
  drawerOpen,
  setDrawerOpen,
  buyerNameOptions,
  merchantNameOptions,
  setFilterBody,
  filterBody,
}) => {
  const buyerNameValueInput = useRef();
  const merchantNameValueInput = useRef();
  const [tablePlan, setTablePlan] = useState({
    buyerNameValue: null,
    merchantNameValue: null,
  });
  const [delivDate, setDelivDate] = useState(null);
  const [cuttingStartDate, setCuttingStartDate] = useState(null);
  const [cuttingEndDate, setCuttingEndDate] = useState(null);
  const [stitchingStartDate, setStitchingStartDate] = useState(null);
  const [stitchingEndDate, setStitchingEndDate] = useState(null);
  const [cuttingGoAhead, setCuttingGoAhead] = useState(null);
  const [onErrorOnDate, setOnErrorOnDate] = useState({
    cuttingStartDate: false,
    cuttingEndDate: false,
    stitchingStartDate: false,
    stitchingEndDate: false,
    delivDate: false,
    cuttingGoAhead: false,
  });
  const [bulkinhouseDate, setBulkinhouseDate] = useState(null);
  const [ppApprovalDate, setPPApprovalDate] = useState(null);
  const alertRef = useRef();

  const handleBuyerNameChange = (event, newValue) => {
    setTablePlan((prev) => ({ ...prev, buyerNameValue: newValue }));
  };

  const handleMerchantNameChange = (event, newValue) => {
    setTablePlan((prev) => ({ ...prev, merchantNameValue: newValue }));
  };

  const handleClose = () => {
    setDrawerOpen(false);
  };

  const handleClearAll = () => {
    setTablePlan({
      buyerNameValue: null,
      merchantNameValue: null,
    });
    setDelivDate(null);
    setCuttingStartDate(null);
    setCuttingEndDate(null);
    setStitchingStartDate(null);
    setStitchingEndDate(null);
    setCuttingGoAhead(null);
    setBulkinhouseDate(null);
    setPPApprovalDate(null);
    setFilterBody([]);
  };

  const handleAddFilter = () => {
    function formatWithLeadingZero(value) {
      return value < 10 ? `0${value}` : value;
    }

    let cuttingStartFormatedDate = cuttingStartDate
      ? `${formatWithLeadingZero(
          cuttingStartDate.date()
        )}-${formatWithLeadingZero(
          cuttingStartDate.month() + 1
        )}-${cuttingStartDate.year()}`
      : null;

    let cuttingEndFormatedDate = cuttingEndDate
      ? `${formatWithLeadingZero(
          cuttingEndDate.date()
        )}-${formatWithLeadingZero(
          cuttingEndDate.month() + 1
        )}-${cuttingEndDate.year()}`
      : null;

    let stitchingStartFormatedDate = stitchingStartDate
      ? `${formatWithLeadingZero(
          stitchingStartDate.date()
        )}-${formatWithLeadingZero(
          stitchingStartDate.month() + 1
        )}-${stitchingStartDate.year()}`
      : null;

    let stitchingEndFormatedDate = stitchingEndDate
      ? `${formatWithLeadingZero(
          stitchingEndDate.date()
        )}-${formatWithLeadingZero(
          stitchingEndDate.month() + 1
        )}-${stitchingEndDate.year()}`
      : null;

    let deliveryFormatedDate = delivDate
      ? `${formatWithLeadingZero(delivDate.date())}-${formatWithLeadingZero(
          delivDate.month() + 1
        )}-${delivDate.year()}`
      : null;

    let goAheadFormatedDate = cuttingGoAhead
      ? `${formatWithLeadingZero(
          cuttingGoAhead.date()
        )}-${formatWithLeadingZero(
          cuttingGoAhead.month() + 1
        )}-${cuttingGoAhead.year()}`
      : null;

    let formattedDates = []; // Use current filterBody as the base

    const updateOrAddFilter = (key, value) => {
      const index = formattedDates.findIndex((item) => item.key === key);
      if (index !== -1) {
        formattedDates[index].value = value; // Update existing value
      } else {
        formattedDates.push({ key, value }); // Add new key-value pair
      }
    };

    if (tablePlan.buyerNameValue) {
      updateOrAddFilter("buyer", tablePlan.buyerNameValue.label);
    }

    if (tablePlan.merchantNameValue) {
      updateOrAddFilter("merchant", tablePlan.merchantNameValue.label);
    }

    if (cuttingStartFormatedDate) {
      updateOrAddFilter("cuttingStartDate", cuttingStartFormatedDate);
    }

    if (cuttingEndFormatedDate) {
      updateOrAddFilter("cuttingEndDate", cuttingEndFormatedDate);
    }

    if (stitchingStartFormatedDate) {
      updateOrAddFilter("stitchingStartDate", stitchingStartFormatedDate);
    }

    if (stitchingEndFormatedDate) {
      updateOrAddFilter("stitchingEndDate", stitchingEndFormatedDate);
    }

    if (deliveryFormatedDate) {
      updateOrAddFilter("deliveryDate", deliveryFormatedDate);
    }

    if (goAheadFormatedDate) {
      updateOrAddFilter("cuttingGoAhead", goAheadFormatedDate);
    }
    console.log(onErrorOnDate);
    const errorItemList = Object.values(onErrorOnDate);

    for (let i = 0; i < errorItemList.length; i++) {
      if (errorItemList[i]) {
        alertRef.current.showAlert(
          ENUMTEXT.WARNING.INVALID_FILTER,
          ENUMTEXT.WARNING.WARNING_SERV
        );
        return;
      }
    }

    for (let i = 0; i < formattedDates.length; i++) {
      if (
        isInvalidDate(
          formattedDates[i].value && formattedDates[i] !== undefined
        )
      ) {
        alertRef.current.showAlert(
          ENUMTEXT.WARNING.INVALID_FILTER,
          ENUMTEXT.WARNING.WARNING_SERV
        );
        return;
      }
    }

    setFilterBody(formattedDates);
    console.log("Data =========>>>>>>>>>>>", formattedDates);
  };

  function renameBuyerNameKey(array) {
    if (!Array.isArray(array)) {
      console.error("Expected an array but received:", array);
      return [];
    }

    return array.map((item) => {
      const { buyerName, ...rest } = item;
      return {
        ...rest,
        label: buyerName,
      };
    });
  }

  const buyerOptions = renameBuyerNameKey(buyerNameOptions);

  function renameContrNameKey(array) {
    if (!Array.isArray(array)) {
      console.error("Expected an array but received:", array);
      return [];
    }

    return array.map((item) => {
      const { name, ...rest } = item;
      return {
        ...rest,
        label: name,
      };
    });
  }

  const contrOptions = renameContrNameKey(merchantNameOptions);

  function isInvalidDate(value) {
    const date = new Date(value);
    return isNaN(date.getTime());
  }

  const onDateChangeError = (value, object) => {
    if (value === null) {
      setOnErrorOnDate((prev) => ({ ...prev, [object]: false }));
    } else {
      setOnErrorOnDate((prev) => ({ ...prev, [object]: true }));
    }
  };

  return (
    <>
      <CustomAlert ref={alertRef} />
      <OrderDrawer open={drawerOpen}>
        <Box borderRadius={2} style={{ height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              paddingRight: "10px",
            }}
          >
            <CancelIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>
          <Typography
            sx={{ textAlign: "center", fontWeight: "bold", fontSize: "15px" }}
          >
            Filters
          </Typography>
          <Box sx={{ marginTop: "30px" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontWeight: 700 }}
              >
                General
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box style={{ paddingBottom: "10px" }}>
                    <CustomAutoComplete
                      value={tablePlan.buyerNameValue}
                      onChange={handleBuyerNameChange}
                      options={buyerOptions}
                      label="Order Number"
                      ref={buyerNameValueInput}
                    />
                  </Box>
                  <Box style={{ paddingBottom: "10px" }}>
                    <CustomAutoComplete
                      value={tablePlan.buyerNameValue}
                      onChange={handleBuyerNameChange}
                      options={buyerOptions}
                      label="Style Detail"
                      ref={buyerNameValueInput}
                    />
                  </Box>
                  <Box style={{ paddingBottom: "10px" }}>
                    <CustomAutoComplete
                      value={tablePlan.buyerNameValue}
                      onChange={handleBuyerNameChange}
                      options={buyerOptions}
                      label="Buyer Name"
                      ref={buyerNameValueInput}
                    />
                  </Box>
                  <Box>
                    <CustomAutoComplete
                      value={tablePlan.merchantNameValue}
                      onChange={handleMerchantNameChange}
                      options={contrOptions}
                      label="Merchant Name"
                      ref={merchantNameValueInput}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontWeight: 700 }}
              >
                Cutting Dates
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Cutting Start Date"
                          value={cuttingStartDate}
                          maxDate={cuttingEndDate}
                          minDate={cuttingGoAhead}
                          onError={(x) =>
                            onDateChangeError(x, "cuttingStartDate")
                          }
                          onChange={(newDate) => setCuttingStartDate(newDate)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Cutting End Date"
                          value={cuttingEndDate}
                          minDate={cuttingStartDate ?? cuttingGoAhead}
                          onChange={(newDate) => setCuttingEndDate(newDate)}
                          onError={(x) =>
                            onDateChangeError(x, "cuttingEndDate")
                          }
                          // shouldDisableDate={(date) => !isDateAvailable(date)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontWeight: 700 }}
              >
                Stitching Dates
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Stitching Start Date"
                          value={stitchingStartDate}
                          maxDate={stitchingEndDate}
                          minDate={
                            cuttingEndDate ?? cuttingStartDate ?? cuttingGoAhead
                          }
                          onError={(x) =>
                            onDateChangeError(x, "stitchingStartDate")
                          }
                          onChange={(newDate) => setStitchingStartDate(newDate)}
                          // shouldDisableDate={(date) => !isDateAvailable(date)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Stitching End Date"
                          value={stitchingEndDate}
                          minDate={
                            stitchingStartDate ??
                            cuttingEndDate ??
                            cuttingStartDate ??
                            cuttingGoAhead
                          }
                          onChange={(newDate) => setStitchingEndDate(newDate)}
                          onError={(x) =>
                            onDateChangeError(x, "stitchingEndDate")
                          }

                          // shouldDisableDate={(date) => !isDateAvailable(date)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <Box style={{ paddingBottom: "10px" }}>
                      <CustomAutoComplete
                        value={tablePlan.buyerNameValue}
                        onChange={handleBuyerNameChange}
                        options={buyerOptions}
                        label="Stitching Line"
                        ref={buyerNameValueInput}
                      />
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{ fontWeight: 700 }}
              >
                Other Dates
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Bulk In-house"
                          value={cuttingGoAhead}
                          maxDate={cuttingStartDate}
                          onChange={(newDate) => setCuttingGoAhead(newDate)}
                          onError={(x) =>
                            onDateChangeError(x, "cuttingGoAhead")
                          }

                          // shouldDisableDate={(date) => !isDateAvailable(date)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="PP-Approval"
                          value={cuttingGoAhead}
                          maxDate={cuttingStartDate}
                          onChange={(newDate) => setCuttingGoAhead(newDate)}
                          onError={(x) =>
                            onDateChangeError(x, "cuttingGoAhead")
                          }

                          // shouldDisableDate={(date) => !isDateAvailable(date)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Delivery Date"
                          value={delivDate}
                          minDate={
                            stitchingEndDate ??
                            stitchingStartDate ??
                            cuttingEndDate ??
                            cuttingStartDate ??
                            cuttingGoAhead
                          }
                          onChange={(newDate) => setDelivDate(newDate)}
                          onError={(x) => onDateChangeError(x, "delivDate")}

                          // shouldDisableDate={(date) => !isDateAvailable(date)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Earliest Cutting Date"
                          value={cuttingGoAhead}
                          maxDate={cuttingStartDate}
                          onChange={(newDate) => setCuttingGoAhead(newDate)}
                          onError={(x) =>
                            onDateChangeError(x, "cuttingGoAhead")
                          }

                          // shouldDisableDate={(date) => !isDateAvailable(date)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "space-around",
              padding: "10px 10px 10px 10px",
            }}
          >
            <CustomButton onClick={handleAddFilter}>Apply Filter</CustomButton>
            <CustomButton onClick={handleClearAll}>Clear All</CustomButton>
          </Box>
        </Box>
      </OrderDrawer>
    </>
  );
};
export default TableAllotmentFilter;
