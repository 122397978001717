import api from './Api';

export const getFloorList = async (requestBody) => {
    try {
        const response = await api.post("/floor-plan/list", requestBody);
        return response;
    } catch (error) {
        return error;
    }
};

export const getFloorDataOverlay =async (oeId, floorPlanId)=>{
    try{
        const response=await api.get("/floor-plan/getOrderLayers",{
            params:{
                oeId:oeId,
                floorPlanId:floorPlanId
            }
        })
        return response;
    }
    catch(error){
        return error
    }

};
export const getAllTable=async(floorPlanId)=>{
    try{
        const response=await api.get("/floor-plan/table/list",{
            params:{
                floorPlanId:floorPlanId
            }
        })
        return response
    }
    catch(error){
        return error
    }
};

export const postTableAllotmentData= async(tableAllotBody)=>{
try{
    const response = await api.post("/floor-plan/table/assign",tableAllotBody);
    return response
}
catch(error){
    return error
}

}

export const DeleteTableRowData= async(RowIdBody)=>{
    try{
        const response =await api.post("floor-plan/delete",RowIdBody);
        return response
    }
    catch(error){
        return error
    }
    
    }