import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DatePickerStatic from "../../components/common/calendars/DatePicker";
import SelectDropdown from "../../components/common/dropdowns/DropDown";
import AgGridTable from "../../components/specific/cuttingTrack/CuttingTrackTable";
import ViewCuttingTrack from "../../components/specific/cuttingTrack/ViewCuttingTrack";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  GetCuttingTrack,
  GetOptionsDetail,
} from "../../services/CuttingTrackServices";
import { useNavigate } from "react-router-dom";
import AddLayerConfirmation from "../../components/specific/draft/AddLayerConfirmation ";
import { useScreenshot } from "use-react-screenshot";

export default function CuttingTrack() {
  const navigate = useNavigate();
  const [viewPopUp, setViewPopUP] = useState(false);
  const [selectedIds, setSelectedIds] = useState({
    orderId: undefined,
    oeId: undefined,
  });
  const [selectedLayer, setSelectedLayer] = useState();
  const [sequenceConfirmationPopUp, setSequenceConfirmationPopUp] =
    useState(false);
  const imageRef = useRef(null);
  const [image, setImage] = useState(null);
  const [screenshot, takeScreenshot] = useScreenshot();
  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 200,
      floatingFilter: true,
    }),
    []
  );
  const ActionCellRenderer = (props) => {
    const handleDownload = () => {
      const Id = props?.node.data.id;
      const oeId = props.node.data.oeId;
      console.log("oeId", oeId);
      setSelectedIds({
        orderId: Id,
        oeId: oeId,
      });
      setViewPopUP(true);
    };

    const handleEditTrack = () => {
      console.log("Id", props.node.data);
      const Id = props?.node.data.id;
      const oeId = props.node.data.oeId;
      console.log("oeId", oeId);
      navigate(`/cutting-track/edittrack`, {
        state: {
          Id: Id,
          oeId: oeId,
        },
      });
    };
    const handleView = () => {
      console.log("Id", props.node.data);
      const Id = props?.node.data.id;
      const oeId = props.node.data.oeId;
      console.log("oeId", oeId);
      setSelectedIds({
        orderId: Id,
        oeId: oeId,
      });
      setViewPopUP(true);
    };

    return (
      <>
        <IconButton onClick={handleEditTrack}>
          <EditIcon />
        </IconButton>
        <IconButton>
          <DownloadIcon onClick={handleDownload} />
        </IconButton>
        <IconButton onClick={handleView}>
          <VisibilityIcon />
        </IconButton>
      </>
    );
  };
  const SelectcellRenderer = (props) => {
    const handleLayerChng = (event) => {
      setSelectedLayer(event.target.value);

      //Api call

      setSequenceConfirmationPopUp(true);
      console.log("Id", props.node.data);
      const Id = props?.node.data.id;
      const oeId = props.node.data.oeId;
      console.log("oeId", oeId);
    };
    const Options = props?.node?.data?.SequenceList ?? [];
    const selectStyle = {
      height: 38,
      display: "flex",
      alignItems: "center",
    };
    const menuProps = {
      PaperProps: {
        style: {
          maxHeight: 100,
          overflowY: "auto",
        },
      },
    };
    return (
      <>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <Select
              defaultValue={0}
              value={selectedLayer}
              style={selectStyle}
              onChange={handleLayerChng}
              MenuProps={menuProps}
            >
              <MenuItem value="0" disabled>
                Sequence
              </MenuItem>
              {Options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </>
    );
  };
  const [columnDefs, setColDefs] = useState([
    {
      field: "OrderNumber",
      filter: "agSetColumnFilter",
      suppressSizeToFit: false,
    },
    {
      field: "BuyerName",
      filter: "agSetColumnFilter",
      suppressSizeToFit: false,
    },
    {
      field: "StyleDetails",
      filter: "agSetColumnFilter",
      suppressSizeToFit: false,
    },
    {
      field: "ContractorName",
      filter: "agSetColumnFilter",
      suppressSizeToFit: false,
    },
    {
      field: "StartDate",
      filter: "agSetColumnFilter",
      suppressSizeToFit: false,
    },
    {
      field: "TableNumber",
      filter: "agSetColumnFilter",
      suppressSizeToFit: false,
    },
    {
      field: "TableLength",
      filter: "agSetColumnFilter",
      suppressSizeToFit: false,
    },
    {
      field: "LayerNumber",
      filter: "agSetColumnFilter",
      suppressSizeToFit: false,
    },
    {
      field: "SizeCombination",
      filter: "agSetColumnFilter",
      suppressSizeToFit: false,
    },
    {
      field: "PlyQuantity",
      filter: "agSetColumnFilter",
      suppressSizeToFit: false,
    },
    {
      field: "PiecesCut",
      filter: "agSetColumnFilter",
      suppressSizeToFit: false,
    },
    {
      field: "RecuttingApplicable",
      filter: "agSetColumnFilter",
      suppressSizeToFit: false,
    },
    {
      field: "MarkerNumber",
      filter: "agSetColumnFilter",
      suppressSizeToFit: false,
    },
    {
      headerName: "Choose Sequence",
      field: "ChooseSequence",
      cellRenderer: SelectcellRenderer,
    },
    {
      field: "action",
      minWidth: 180,
      cellRenderer: ActionCellRenderer,
    },
  ]);
  const [FromDate, setFromDate] = useState();
  const [ToDate, setToDate] = useState();
  const [modifiedDate, setModifiedDate] = useState({
    modifiedFromDate: undefined,
    modifiedToDate: undefined,
  });
  const [rowData, setRowData] = useState([]);
  const alertRef = useRef();
  const [cuttingTrackData, setCuttingTrackData] = useState();
  const [tableOptions, setTableOptions] = useState([]);
  const [contractorsName, setContractorsName] = useState([]);
  const [contractorName, setContractorName] = useState(null);
  const [tableName, setTableName] = useState(null);
  const [sorting, setSorting] = useState({
    sortBy: "contractorName",
    direction: "ASC",
  });

  const [pagination, setPagination] = useState({
    pageNo: 0,
    pageSize: 10,
  });
  const requestBody = {
    filters: [],
    sorting,
    pageNo: pagination.pageNo,
    pageSize: pagination.pageSize,
  };
  const languages = ["English", "Spanish", "French", "Portuguese", "(other)"];
  const getRandomNumber = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const handleFromDateChng = async (event) => {
    setFromDate(event);
    const request = requestBody;
    const date = new Date(event);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    setModifiedDate((prev) => ({
      ...prev,
      modifiedFromDate: `${day}-${month}-${year}`,
    }));

    const filterObject1 = [
      { key: "allottedDateFrom", value: `${day}-${month}-${year}` },
    ];

    if (modifiedDate.modifiedToDate !== undefined) {
      filterObject1.push({
        key: "allottedDateTo",
        value: modifiedDate.modifiedToDate,
      });
    }
    if (contractorName !== null) {
      filterObject1.push({
        key: "contractorName",
        value: contractorName,
      });
    }
    if (tableName !== null) {
      filterObject1.push({
        key: "tableMaster",
        value: tableName,
      });
    }
    request.filters = filterObject1;
    const response = await getCuttingTrack(request);
    console.log("res", response);
    if (response === undefined) return;
    const arr = response?.map((item) => ({
      OrderNumber: item.orderId,
      BuyerName: item.buyerName,
      StyleDetails: item.styleDetails || null,
      ContractorName: item.contractorName,
      StartDate: item.cuttingStartDate,
      TableNumber: item.tableMaster.tableNumber,
      TableLength: item.tableMaster.length,
      LayerNumber: item.layerName || null,
      SizeCombination: item.sizeCombination || null,
      PlyQuantity: item.plyQty || 0,
      PiecesCut: item.consumedFabric || 0,
      RecuttingApplicable: item.reCuttingApplied === true ? "true" : "false",
      MarkerNumber: item.markerNumber || null,
      SequenceList: createList(10),
      SequenceValue: 5,

      oeId: item.oeId,
      id: item.id,
    }));
    console.log("arr", arr);
    setRowData(arr);
    console.log("rowData", rowData);
  };

  function createList(n) {
    const list = [];
    for (let i = 1; i <= n; i++) {
      list.push(i);
    }
    return list;
  }

  const handleToDateChng = async (event) => {
    setToDate(event);
    const request = requestBody;
    const date = new Date(event);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    setModifiedDate((prev) => ({
      ...prev,
      modifiedToDate: `${day}-${month}-${year}`,
    }));

    const filterObject1 = [
      {
        key: "allottedDateTo",
        value: `${day}-${month}-${year}`,
      },
    ];
    if (modifiedDate.modifiedFromDate !== undefined) {
      filterObject1.push({
        key: "allottedDateFrom",
        value: modifiedDate.modifiedFromDate,
      });
    }
    if (contractorName !== null) {
      filterObject1.push({
        key: "contractorName",
        value: contractorName,
      });
    }
    if (tableName !== null) {
      filterObject1.push({
        key: "tableMaster",
        value: tableName,
      });
    }
    request.filters = filterObject1;
    const response = await getCuttingTrack(request);
    if (response === undefined) return;
    const arr = response?.map((item) => ({
      OrderNumber: item.orderId,
      BuyerName: item.buyerName,
      StyleDetails: item.styleDetails || null,
      ContractorName: item.contractorName,
      StartDate: item.cuttingStartDate,
      TableNumber: item.tableMaster.tableNumber,
      TableLength: item.tableMaster.length,
      LayerNumber: item.layerName || null,
      SizeCombination: item.sizeCombination || null,
      PlyQuantity: item.plyQty || 0,
      PiecesCut: item.consumedFabric || 0,
      RecuttingApplicable: item.reCuttingApplied === true ? "true" : "false",
      MarkerNumber: item.markerNumber || null,
      SequenceList: createList(10),
      SequenceValue: 5,
      oeId: item.oeId,
      id: item.id,
    }));
    console.log("arr", arr);
    setRowData(arr);
    console.log("rowData", rowData);
  };

  const handleOptionChng = async (event) => {
    setTableName(null);
    setContractorName(event.target.value);

    const request = requestBody;
    const filterObject1 = [
      {
        key: "contractorName",
        value: event.target.value,
      },
    ];
    if (modifiedDate.modifiedFromDate !== undefined) {
      filterObject1.push({
        key: "allottedDateFrom",
        value: modifiedDate.modifiedFromDate,
      });
    }
    if (modifiedDate.modifiedToDate !== undefined) {
      filterObject1.push({
        key: "allottedDateTo",
        value: modifiedDate.modifiedToDate,
      });
    }
    request.filters = filterObject1;
    const response = await getCuttingTrack(request);

    if (response === undefined) return;

    const arr = response?.map((item) => ({
      OrderNumber: item.orderId,
      BuyerName: item.buyerName,
      StyleDetails: item.styleDetails || null,
      ContractorName: item.contractorName,
      StartDate: item.cuttingStartDate,
      TableNumber: item.tableMaster.tableNumber,
      TableLength: item.tableMaster.length,
      LayerNumber: item.layerName || null,
      SizeCombination: item.sizeCombination || null,
      PlyQuantity: item.plyQty || 0,
      PiecesCut: item.consumedFabric || 0,
      RecuttingApplicable: item.reCuttingApplied === true ? "true" : "false",
      MarkerNumber: item.markerNumber || null,
      SequenceList: createList(10),
      SequenceValue: 5,
      oeId: item.oeId,
      id: item.id,
    }));
    console.log("arr", arr);
    setRowData(arr);
    console.log("rowData", rowData);
  };

  const handleTableChng = async (event) => {
    setContractorName(null);
    setTableName(event.target.value);

    const request = requestBody;
    const filterObject1 = [
      {
        key: "tableMaster",
        value: event.target.value,
      },
    ];
    if (modifiedDate.modifiedFromDate !== undefined) {
      filterObject1.push({
        key: "allottedDateFrom",
        value: modifiedDate.modifiedFromDate,
      });
    }
    if (modifiedDate.modifiedToDate !== undefined) {
      filterObject1.push({
        key: "allottedDateTo",
        value: modifiedDate.modifiedToDate,
      });
    }
    request.filters = filterObject1;
    const response = await getCuttingTrack(request);
    if (response === undefined) return;
    const arr = response?.map((item) => ({
      OrderNumber: item.orderId,
      BuyerName: item.buyerName,
      StyleDetails: item.styleDetails || null,
      ContractorName: item.contractorName,
      StartDate: item.cuttingStartDate,
      TableNumber: item.tableMaster.tableNumber,
      TableLength: item.tableMaster.length,
      LayerNumber: item.layerName || null,
      SizeCombination: item.sizeCombination || null,
      PlyQuantity: item.plyQty || 0,
      PiecesCut: item.consumedFabric || 0,
      RecuttingApplicable: item.reCuttingApplied === true ? "true" : "false",
      MarkerNumber: item.markerNumber || null,
      SequenceList: createList(10),
      SequenceValue: 5,
      oeId: item.oeId,
      id: item.id,
    }));
    console.log("arr", arr);
    setRowData(arr);
    console.log("rowData", rowData);
  };

  const getCuttingTrack = async (requestBody) => {
    try {
      const response = await GetCuttingTrack(requestBody);

      if (response.status === 200) {
        const responseData = response.data.response;
        setCuttingTrackData(responseData);
        return responseData;
        console.log("responseData", responseData);
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (err) {
      console.error("GetError", err);
    }
  };
  const OptionsDetails = async () => {
    try {
      const response = await GetOptionsDetail();

      if (response.status === 200) {
        const responseData = response.data.response;
        const tabledata = responseData.tables;
        setTableOptions(tabledata);
        const contractordata = responseData.contractors;
        setContractorsName(contractordata);
        console.log("responseData", responseData);
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (err) {
      console.error("GetError", err);
    }
  };
  useEffect(() => {
    OptionsDetails();
  }, []);
  return (
    <>
      <Grid container spacing={1} padding={2}>
        <Grid item xs={12} sm={6} md={6} lg={2.4} xl={2.4}>
          <DatePickerStatic
            label={"Choose From Date"}
            value={FromDate}
            onChange={handleFromDateChng}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={2.4} xl={2.4}>
          <DatePickerStatic
            label={"Choose To Date"}
            value={ToDate}
            onChange={handleToDateChng}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          lg={2.4}
          xl={2.4}
          style={{ paddingTop: "15px" }}
        >
          <SelectDropdown
            label="Select Contractor Name"
            Options={contractorsName}
            value={contractorName}
            onChange={handleOptionChng}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={0.4}
          xl={0.4}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h7" style={{ margin: "0", paddingLeft: "0" }}>
            OR
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          lg={2.4}
          xl={2.4}
          style={{ paddingTop: "15px" }}
        >
          <SelectDropdown
            label="Select Table Number"
            Options={tableOptions}
            value={tableName}
            onChange={handleTableChng}
          />
        </Grid>
      </Grid>

      <ViewCuttingTrack
        open={viewPopUp}
        orderId={selectedIds.orderId}
        oeId={selectedIds.oeId}
        handleClose={() => {
          setSelectedIds({ orderId: undefined, oeId: undefined });
          setViewPopUP(false);
        }}
      />
      <AddLayerConfirmation
        open={sequenceConfirmationPopUp}
        // handleConfirm={submitLot}
        // handleUpdateLot={() => {
        //   setAddLotConfirmationPopUp(false);
        // }}
        confirmText={"Cancel"}
        updateText={"Change Sequence"}
        Dialogtitle={"Sequencing"}
        Dialogtext={
          "The Sequencing existing before the selected sequence is not alloted to any layer of the selected order "
        }
      />

      <AgGridTable
        colData={columnDefs}
        rowData={rowData}
        pagination={true}
        defaultColDef={defaultColDef}
      />
    </>
  );
}
