import api from "./Api";

async function getFreezedDraft(oeId) {
  try {
    const url = `/draft/byOEId?oeId=${oeId}`;
    const response = await api.get(url);
    return response;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    return error;
  }
}

async function downloadFiles(id) {
  try {
    const url = `/file/getFile?id=${id}`;
    const response = await api.post(url, null, { responseType: "blob" });
    return response;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    return error;
  }
}

export { getFreezedDraft, downloadFiles };
