import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomTable from "../components/common/tables/CustomTable";

import Drawer from "@mui/material/Drawer";
import { Box, Typography, IconButton, Button, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  DeleteTableRowData,
  getAllTable,
  getFloorDataOverlay,
  getFloorList,
} from "../services/FloorPlanService";
import * as ENUMTEXT from "../constants/AlertText";
import CustomAlert from "../components/common/CustomAlert";
import "../App.css";
import debounce from "lodash.debounce";
import FloorOverlay from "../components/specific/floor/FloorOverlayPage";
import DeleteTableLayer from "../components/specific/floor/DeleteTableLayer";
import MenuIcon from "@mui/icons-material/Menu";

var filterParamsDate = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  minValidYear: 2000,
  maxValidYear: 3000,
  inRangeFloatingFilterDateFormat: "Do MMM YYYY",
};

const createFakeServer = (allData) => {
  return {
    getData: (request) => {
      const requestedRows = allData.responseList.slice(
        request.startRow,
        request.endRow
      );
      return {
        success: true,
        rows: requestedRows,
        lastRow: allData.totalElements,
      };
    },
  };
};

const defaultFilterParams = {
  filters: [
    {
      key: "orderId",
      value: "",
    },
    {
      key: "buyer",
      value: "",
    },
    {
      key: "buyerPONumber",
      value: "",
    },
    {
      key: "style",
      value: "",
    },
    {
      key: "size",
      value: "",
    },
    {
      key: "color",
      value: "",
    },
    {
      key: "styleDescription",
      value: "",
    },
    {
      key: "orderQuantity",
      value: "",
    },
    {
      key: "deliveryDate",
      value: "",
    },
    {
      key: "bulkInHouse",
      value: "",
    },
    {
      key: "bulkQuantity",
      value: "",
    },
    {
      key: "ppApproval",
      value: "",
    },
    {
      key: "ppApproval",
      value: "",
    },
    {
      key: "sizeSet",
      value: "",
    },
    {
      key: "embroidery",
      value: "",
    },
    {
      key: "cuttingStartDate",
      value: "",
    },
    {
      key: "cuttingEndDate",
      value: "",
    },
    {
      key: "cuttingQuantity",
      value: "",
    },
    {
      key: "line",
      value: "",
    },
    {
      key: "stitchingStartDate",
      value: "",
    },
  ],
  sorting: {
    sortBy: "orderId",
    direction: "ASC",
  },
  // pageSize: 10,
  pageNo: 0,
};

export default function FloorPlan() {
  const alertRef = useRef();
  const [selectedData, setSelectedData] = useState(null);
  const [selectedTableAllotData, setSelectedTableAllotDataLayer] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [layerOptions, setLayerOptions] = useState([]);
  const [tableOptions, setTableOptions] = useState([]);
  const [ContractorOptions, setContractorOptions] = useState([]);
  const [RecuttingOptions, setRecuttingOptions] = useState(["Yes", "No"]);
  const [expandTable, setExpandTable] = useState(true);
  const [availableDates, setAvailableDates] = useState([]);
  const [showTableSch, setShowTableSch] = useState(false);
  const [tableAllotId, setTableAllotId] = useState();
  const [layerAllotId, setLayerAllotId] = useState();
  const [layerAllotIdLength, setLayerAllotIdLength] = useState();
  const [MatchedTableData, setMatchedTableData] = useState();
  const [LayerTableData, setLayerTableData] = useState();
  const [tableOneRowData, setTableOneRowData] = useState([]);
  const [tableTwoRowData, setTableTwoRowData] = useState([]);
  const [getTableValue, setGetTableValue] = useState();
  const [reCuttingValue, setRecuttingValue] = useState();
  const [editDataOnOverlay, setEditDataOnOverlay] = useState();
  const [isEditButtonClicked, setIsEditButtonClicked] = useState(false);
  const [TableAllListData, setTableAllListData] = useState();
  const [TableAllListAvailableData, setTableAllListAvailableData] = useState();
  const [tableNoLyrComOptions, setTableNoLyrComOptions] = useState([]);
  const [deleteTableLayerIdPopUpOpen, setDeleteTableLayerIdPopUpOpen] =
    useState(false);
  const [tableTwoSelectedTable, setTableTwoSelectedTable] = useState({
    tableTwoId: undefined,
    tableTwoName: undefined,
  });
  const [deleteTableLayerId, setDeleteTableLayerId] = useState({
    tableid: undefined,
    floorPlanId: undefined,
    TableLayerId: undefined,
  });
  const [clearFieldsOnDeleteId, setClearFieldsOnDeleteId] = useState(undefined);

  //for drawer

  const ActionCellRenderer = (props) => {
    return (
      <span
        onClick={() => {
          console.log("Floor Plan Data", props.node.data);
          setSelectedData(props.node.data);
          setDrawerOpen(true);
        }}
        style={{ textDecoration: "underline", cursor: "pointer" }}
      >
        {props.node.data.orderId}
      </span>
    );
  };

  const TableTwoActionCellRenderer = (props) => {
    return (
      <span
        onClick={() => {
          setTableAllotId(props.node.data.id);
          setTableTwoSelectedTable({});
        }}
        style={{ textDecoration: "underline", cursor: "pointer" }}
      >
        {props.node.data.tableNumber}
      </span>
    );
  };

  const updateAllTable = async (id) => {
    const tableData = await getAllTable(id);
    const tableAllData = tableData?.data?.response?.tableWithTablePlanMap; // all list data
    setTableAllListData(tableAllData);
  };

  const handleDeleteClick = async () => {
    try {
      const RowIdBody = {
        id: deleteTableLayerId.TableLayerId,
      };
      const response = await DeleteTableRowData(RowIdBody);
      console.log("API Response", response);
      if (response.status === 200) {
        setClearFieldsOnDeleteId(deleteTableLayerId.TableLayerId);
        alertRef.current.showAlert(
          ENUMTEXT.SUCCESS.TABLE_ALLOTMENT_DELETE_SUCCESS,
          ENUMTEXT.SUCCESS.WARNING_SERV
        );
        const tableData = await getAllTable(deleteTableLayerId.floorPlanId);
        const tableAllData = tableData?.data?.response?.tableWithTablePlanMap; // all list data
        setTableAllListData(tableAllData);
        console.log(
          "post data response",
          tableData.data.response.tableWithTablePlanMap
        );
        console.log(
          "post data response",
          tableData.data.response.tableWithTablePlanMap
        );
        const tableSetRowDataId = deleteTableLayerId.tableid;
        console.log("tableIdPost", tableSetRowDataId);

        const tableRows =
          tableData.data.response.tableWithTablePlanMap[tableSetRowDataId];

        if (tableRows && tableRows.length > 0 && tableData.data !== "") {
          const transformedTableOneData = tableRows.map((item) => ({
            startDate: item.allotmentDate,
            orderNo: item.orderId,
            layer: item.layerName,
            sizeCombination: item.sizeCombination, // I have to check this
            quantity: item.quantity,
            recuttingApplied: item.reCuttingApplied === true ? "Yes" : "No",
            tableNumber: item.tableMaster.tableNumber,
            tableId: item.tableMaster.id,
            ot: item.ot,
            contractorId: item.contractorId,
            contractorName: item.contractorName,
            style: item.style,
            plyQty: item.plyQty, // come from Backend

            id: item.id,
            floorPlanId: item.floorPlanId,
            orderId: item.orderId,
            orderRefId: item.orderRefId,
            allotmentDate: item.allotmentDate,
            layerId: item.layerId,
            layerName: item.layerName,
          }));

          console.log("tableRowsData", tableRows);
          setTableOneRowData(transformedTableOneData);
        } else {
          setTableOneRowData([]);
        }

        // const tableOneData=
        // setDataOnTableOne()
      } else {
        alertRef.current.showAlert(
          ENUMTEXT.WARNING.TABLE_ALLOTMENT_DELETE_FAILED,
          ENUMTEXT.WARNING.WARNING_SERV
        );
      }
      setDeleteTableLayerIdPopUpOpen(false);
    } catch (error) {
      console.log("error");
    }
  };

  const TableOneActionCellRenderer = (props) => {
    const handleEditLayer = async () => {
      console.log("edit button is clicked", props.node.data);
      setEditDataOnOverlay(props.node.data);
      setIsEditButtonClicked(true);
    };
    //Delete the row

    return (
      <>
        <IconButton onClick={handleEditLayer}>
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setDeleteTableLayerIdPopUpOpen(true);
            setDeleteTableLayerId({
              tableid: props.node.data.tableId,
              floorPlanId: props.node.data.floorPlanId,
              TableLayerId: props.node.data.id,
            });
          }}
        >
          <DeleteIcon />
        </IconButton>
      </>
    );
  };

  const [showTable, setShowTable] = useState(false);
  const [filterParams, setFilterParams] = useState(defaultFilterParams);
  const [gridParams, setGridParams] = useState();
  let initialCall = false;
  let lastRequest = {};

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Order Number",
      field: "orderId",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: ActionCellRenderer,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "buyer",
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Buyer PO No",
      field: "buyerPONumber",
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "style",
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "size",
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "color",
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "styleDescription",
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "orderQuantity",
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Ex-factory Delivery Date",
      field: "deliveryDate",
      minWidth: 200,
      filter: "agDateColumnFilter",
      filterParams: filterParamsDate,
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      headerName: "Bulk Inhouse",
      field: "bulkInHouse",
      filter: "agDateColumnFilter",
      filterParams: filterParamsDate,
      minWidth: 200,
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      headerName: "Bulk Qty/Percentage",
      field: "bulkQuantity",
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "ppApproval",
      headerName: "PP Approval",
      filter: "agDateColumnFilter",
      filterParams: filterParamsDate,
      minWidth: 200,
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      field: "sizeSet",
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "embroidery",
      enableRowGroup: true,
      filter: "agDateColumnFilter",
      filterParams: filterParamsDate,
      minWidth: 200,
      enablePivot: true,
    },
    {
      field: "cuttingStartDate",
      filter: "agDateColumnFilter",
      filterParams: filterParamsDate,
      minWidth: 200,
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      field: "cuttingEndDate",
      filter: "agDateColumnFilter",
      filterParams: filterParamsDate,
      minWidth: 200,
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      field: "cuttingQuantity",
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "line",
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "stitchingStartDate",
      filter: "agDateColumnFilter",
      filterParams: filterParamsDate,
      minWidth: 200,
      enableRowGroup: true,
      enablePivot: true,
    },
  ]);

  const [overlaySelectedData, setOverlaySelectedData] = useState(null);

  const findMatchingObject = (layerId, tableAllData) => {
    for (const key in tableAllData) {
      if (tableAllData.hasOwnProperty(key)) {
        const objectsArray = tableAllData[key];
        for (const obj of objectsArray) {
          if (obj.layerId == layerId) {
            return obj;
          }
        }
      }
    }
    return null; // Return null if no match is found
  };

  const getDataByTableId = (data, tableId) => {
    let matchingData = [];
    let restData = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const items = data[key];
        items.forEach((item) => {
          if (item.tableMaster.id == tableId) {
            matchingData.push(item);
          } else {
            restData.push(item);
          }
        });
      }
    }

    return { matchingData, restData };
  };

  const fetchDataFromOeId = async ({
    layerAllotedId,
    layerAllotedIdLength,
  }) => {
    if (layerAllotedId === undefined) {
      setLayerAllotId(undefined);
    }
    if (layerAllotedIdLength === undefined) {
      setLayerAllotIdLength(undefined);
    }
    if (selectedData) {
      console.log("selected Data", selectedData);
      const result = await getFloorDataOverlay(
        selectedData?.oeId, //oeId
        selectedData?.id //floorPlanId
      );

      //all table data whose layer is alloted
      const tableData = await getAllTable(selectedData?.id); // taking floorPlan id

      console.log("result recutting", result?.data?.response?.reCuttingApplied);
      setRecuttingValue(result?.data?.response?.reCuttingApplied);
      let latestSelectedData = selectedData;
      latestSelectedData.reCuttingApplied =
        result?.data?.response?.reCuttingApplied;

      setOverlaySelectedData(selectedData);

      //got the layer id which is selected
      const layerId = String(layerAllotedId); //  layerAllotId come from floorOverlay, come from the child component
      console.log("layerId", layerId);
      const layerLength = layerAllotedIdLength;
      console.log("layerLength", layerLength);
      //all table data
      // const tableAllData = tableData?.data?.responseMap;
      const tableAllData = tableData?.data?.response?.tableWithTablePlanMap; // all list data
      console.log("tableData", tableAllData);
      setTableAllListData(tableAllData);

      //Table all list date available
      const tableAvailableDates =
        tableData?.data?.response?.tableWithAvailableDates;
      console.log("tableAvailableData", tableAvailableDates);
      setTableAllListAvailableData(tableAvailableDates);

      console.log(
        "responseofTableAllotment",
        result.data?.response?.layerDTOS?.layerName
      );
      const layers = result?.data?.response?.layerDTOS || [];
      const tables = result?.data?.response?.tablePlanDTOS || [];
      const constructor = result?.data?.response?.contractors || [];
      const availableDate = result?.data?.response?.availableDates || [];

      const layerNames = layers.map((layer) => ({
        id: layer.id,
        layerName: layer.layerName,
        sizes: layer.sizes,
        plyQty: layer.plyQty,
        length: layer.length,
        reCuttingApplied: layer.reCuttingApplied,
      }));
      // const tableNames = tables.map((table) => ({
      //   id: table.id,
      //   tableNumber: table.tableNumber,
      // }));
      let tableNames = [];
      if (layerLength === undefined) {
        tableNames = tables.map((table) => ({
          id: table.id,
          tableNumber: table.tableNumber,
          length: table.length,
        }));
      } else {
        tableNames = tables
          .filter((table) => parseFloat(table.length) >= layerLength)
          .map((table) => ({
            id: table.id,
            tableNumber: table.tableNumber,
            length: table.length,
          }));
      }
      const constructors = constructor.map((cons) => ({
        id: cons.id,
        name: cons.name,
      }));

      const tableNoLayerComparison = tables.map((table) => ({
        id: table.id,
        tableNumber: table.tableNumber,
      }));

      console.log("availableDate", availableDate);
      console.log("constructordate", constructors);
      setTableNoLyrComOptions(tableNoLayerComparison);
      setLayerOptions(layerNames); // Update layerOptions with the fetched data
      setTableOptions(tableNames);
      setAvailableDates(availableDate);
      setContractorOptions(constructors);
      console.log("result", result);

      // selected table id
      const tableId = tableAllotId; // Replace with the desired tableId
      console.log("tableId", tableId);

      console.log("tableAlloted===", layerId, tableAllData);
      const filteredObject = findMatchingObject(layerId, tableAllData);
      console.log("Filtered Object:", filteredObject);

      const { matchingData, restData } = getDataByTableId(
        tableAllData,
        tableId
      );
      console.log("Matching Data:", matchingData);
      console.log("Rest Data:", restData);

      // data for table1
      //all table list se data aa  rha hai
      const transformedTableOneData = matchingData.map((item) => ({
        startDate: item.allotmentDate,
        orderNo: item.orderId,
        layer: item.layerName,
        sizeCombination: item?.sizeCombination,
        quantity: item.quantity,
        recuttingApplied: item.reCuttingApplied === true ? "Yes" : "No",
        tableNumber: item.tableMaster.tableNumber,
        tableId: item.tableMaster.id,
        ot: item.ot,
        contractorId: item.contractorId,
        contractorName: item.contractorName,
        style: item.style,
        plyQty: item?.plyQty, // come from Backend
        id: item.id,
        orderId: item.orderId,
        oeId: item.oeId,
        orderRefId: item.orderRefId,
        floorPlanId: item.floorPlanId,
        allotmentDate: item.allotmentDate,
        layerId: item.layerId,
        layerName: item.layerName,
      }));

      // Set the transformed data to state for CustomTable
      setTableOneRowData(transformedTableOneData);

      // data for table2

      const tableLayerInfo = Object.keys(tableAllData).reduce(
        (acc, tableId) => {
          const layers = tableAllData[tableId];
          acc[tableId] = {
            noOfLayers: layers.length,
            totalQty: layers.reduce((sum, layer) => sum + layer.quantity, 0),
          };
          return acc;
        },
        {}
      );

      const transformedTableTwoData = tableNames
        .filter((item) => item.id !== tableId)
        .map((item) => ({
          tableNumber: item?.tableNumber,
          tableLength: item?.length,
          noOfLayers: tableLayerInfo[item.id]?.noOfLayers || 0,
          totalQty: tableLayerInfo[item.id]?.totalQty || 0,
          availableDate: tableAvailableDates[item.id]?.[0] || "-",
          // noOfLayers: item?.noOfLayers,
          // totalQty: item?.totalQuantity,
        }));
      console.log(tableOptions);

      const tableList = Object.keys(tableAllData)
        .filter((item) => Number(item) !== tableId)
        .map((item) => {
          const list = tableAllData[item];
          const availableDate = tableAvailableDates[item][0];
          const qty = list.filter((x) => x.allotmentDate === availableDate);
          const totalqty = qty.reduce((sum, layer) => sum + layer.quantity, 0);
          const tableMaster = list[0].tableMaster;
          console.log(list, availableDate);

          return {
            tableNumber: tableMaster.tableNumber,
            tableLength: tableMaster.length, //item?.length,
            noOfLayers: availableDate === undefined ? "-" : qty.length || 0,
            totalQty: availableDate === undefined ? "-" : totalqty || 0,
            availableDate: availableDate || "-",
            id: tableMaster.id,
          };
        });
      const extraTableList = tableNames
        .filter(
          (item) =>
            !tableList.map((x) => x.tableNumber).includes(item.tableNumber) &&
            item.id !== tableId
        )
        .map((item) => {
          const id = item.id.toString();
          const availableDate = tableAvailableDates[id][0];
          console.log(availableDate);
          return {
            tableNumber: item.tableNumber,
            tableLength: item.length, //item?.length,
            noOfLayers: availableDate === undefined ? "-" : 0, //qty.length || 0,
            totalQty: availableDate === undefined ? "-" : 0, // totalqty || 0,
            availableDate: availableDate || "-",
            id: item.id,
          };
        });

      setTableTwoRowData([...tableList, ...extraTableList]);
      setLayerTableData(filteredObject);
    }
  };

  const resetTableList = async () => {
    if (selectedData) {
      const result = await getFloorDataOverlay(
        selectedData?.oeId, //oeId
        selectedData?.id //floorPlanId
      );
      const tables = result?.data?.response?.tablePlanDTOS || [];
      let tableNames = [];
      tableNames = tables.map((table) => ({
        id: table.id,
        tableNumber: table.tableNumber,
        length: table.length,
      }));
      setTableOptions(tableNames);
    }
  };

  useEffect(() => {
    fetchDataFromOeId({
      layerAllotedId: undefined,
      layerAllotedIdLength: layerAllotIdLength,
    });
    console.log("overlayDSelectedData", overlaySelectedData);
  }, [selectedData]);

  useEffect(() => {
    fetchDataFromOeId({
      layerAllotedId: undefined,
      layerAllotedIdLength: undefined,
    });
    console.log("overlayDSelectedData", overlaySelectedData);
  }, [overlaySelectedData]);

  useEffect(() => {
    if (layerAllotId !== undefined) {
      fetchDataFromOeId({
        layerAllotedId: layerAllotId,
        layerAllotedIdLength: layerAllotIdLength,
      });
    }
    console.log("overlayDSelectedData", overlaySelectedData);
  }, [layerAllotId]);

  useEffect(() => {
    if (tableAllotId === null || tableAllotId === undefined) {
      return;
    } else {
      fetchDataFromOeId({
        layerAllotedId: undefined,
        layerAllotedIdLength: layerAllotIdLength,
      });
    }
  }, [tableAllotId]);
  // }, [selectedData, tableAllotId, layerAllotId, overlaySelectedData]);

  const createServerSideDatasource = (server) => {
    return {
      getRows: (params) => {
        console.log("[Datasource] - rows requested by grid: ", params.request);
        const requestChanged =
          JSON.stringify(params.request) !== JSON.stringify(lastRequest);
        lastRequest = { ...params.request };
        if (initialCall && requestChanged) {
          debouncedFiltering(params);
        }
        const response = server.getData(params.request);
        setTimeout(() => {
          if (response.success) {
            console.log(response.rows);
            console.log(response);
            params.success({
              rowData: response.rows,
              rowCount: response.lastRow,
            });
          } else {
            params.fail();
          }
        }, 500);
        initialCall = true;
      },
    };
  };

  const filtering = (params) => {
    const { startRow, endRow, sortModel, filterModel } = params.request;
    const sort = sortModel.length > 0 ? sortModel[0] : {};
    const filters = Object.keys(filterModel).map((key) => ({
      key,
      value: filterModel[key].filter,
    }));
    const newFilterParams = {
      filters,
      sorting: {
        sortBy: sort.colId || "orderId",
        direction: sort.sort || "ASC",
      },
      pageSize: endRow - startRow,
      pageNo: startRow / (endRow - startRow),
    };
    fetchFloorData(newFilterParams, params);
  };
  const debouncedFiltering = debounce(filtering, 3000);

  const fetchFloorData = async (filterParams, params) => {
    const response = await getFloorList(filterParams);
    if (response.status === 200) {
      const fakeServer = createFakeServer(response.data);
      const datasource = createServerSideDatasource(fakeServer);
      params.api.setGridOption("serverSideDatasource", datasource);
    } else {
      alertRef.current.showAlert(response, ENUMTEXT.ERROR.ERROR_SERV);
    }
  };

  const handleGridReady = useCallback((params) => {
    fetchFloorData(filterParams, params);
  }, []);

  const [TableOnecolDefs, setTableOneColDefs] = useState([
    { field: "startDate" },
    { field: "orderNo" },
    { field: "layer" },
    { field: "sizeCombination" },
    { field: "quantity" },
    { field: "recuttingApplied", headerName: "Re-cutting Applied" },
    {
      field: "action",
      cellRenderer: TableOneActionCellRenderer,
    },
  ]);

  const [TableTwocolDefs, setTableTwoColDefs] = useState([
    // { field: "tableNumber" },
    {
      field: "tableNumber",
      cellRenderer: TableTwoActionCellRenderer,
    },
    { field: "tableLength" },
    { field: "availableDate" },
    { field: "noOfLayers" },
    { field: "totalQty" },
  ]);

  const handleCloseTable = () => {
    console.log("you have clicked close button");
    setShowTableSch(false);
  };

  const handleExpandTable = () => {
    console.log("you have clicked to expand table");

    setExpandTable(!expandTable);
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <CustomAlert ref={alertRef} />
        {!drawerOpen && selectedData !== null && (
          <IconButton
            sx={{
              position: "absolute",
              top: "-40px",
              right: "20px",
              background: "fff",
              "&:hover": {},
            }}
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon sx={{ fontSize: "1.2rem !important" }} />
          </IconButton>
        )}
        <Box
          sx={{
            width: drawerOpen ? `calc(100% - 250px)` : "100%",
            height: showTableSch ? "50vh" : "auto",
            overflowY: showTableSch ? "auto" : "visible",
            scrollbarWidth: "none",
          }}
        >
          <CustomTable
            colDefs={colDefs}
            whichPage={"FloorPlan"}
            onGridReady={handleGridReady}
            serverSideSort={true}
            serverSideFilter={true}
            serverSidePagination={true}
            style={{ overflowY: showTableSch ? "auto" : "visible" }}
          />
        </Box>
        <DeleteTableLayer
          open={deleteTableLayerIdPopUpOpen}
          handleClose={() => {
            setDeleteTableLayerId({
              tableid: undefined,
              floorPlanId: undefined,
              TableLayerId: undefined,
            });
            setDeleteTableLayerIdPopUpOpen(false);
          }}
          handleConfirm={handleDeleteClick}
        />

        <FloorOverlay
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          layerOptions={layerOptions}
          TableOptions={tableOptions}
          ContractorOptions={ContractorOptions}
          RecuttingOptions={RecuttingOptions}
          overlaySelectedData={overlaySelectedData}
          availableDates={availableDates}
          setShowTableSch={setShowTableSch}
          setTableAllotId={setTableAllotId}
          tableAllotId={tableAllotId}
          setLayerAllotId={setLayerAllotId}
          setLayerAllotIdLength={setLayerAllotIdLength}
          MatchedTableData={MatchedTableData}
          LayerTableData={LayerTableData}
          setOverlaySelectedData={setOverlaySelectedData}
          setGetTableValue={setGetTableValue}
          reCuttingValue={reCuttingValue}
          editDataOnOverlay={editDataOnOverlay}
          isEditButtonClicked={isEditButtonClicked}
          TableAllListData={TableAllListData}
          TableAllListAvailableData={TableAllListAvailableData}
          setTableOneRowData={setTableOneRowData}
          tableNoLyrComOptions={tableNoLyrComOptions}
          fetchDataFromOeId={fetchDataFromOeId}
          setLayerOptions={setLayerOptions}
          updateAllTable={updateAllTable}
          deleteTableLayerId={deleteTableLayerId}
          clearFieldsOnDeleteId={clearFieldsOnDeleteId}
          setClearFieldsOnDeleteId={setClearFieldsOnDeleteId}
          resetTableList={resetTableList}
        />

        {console.log("getvalue", getTableValue)}

        {/* show table schedule */}
        {/* {showTableSch && (
        <hr style={{ marginTop: "10px" }} />
      )} */}

        <Slide
          direction="up"
          in={showTableSch}
          mountOnEnter
          unmountOnExit
          sx={{
            width: drawerOpen ? `calc(100% - 250px)` : "100%",
            height: showTableSch ? "40vh" : "auto",
            overflowY: showTableSch ? "auto" : "visible",
            scrollbarWidth: "none",
          }}
        >
          <Box
            sx={{
              display: showTableSch ? "none" : "block",
              paddingRight: "10px",
              position: "absolute",
              top: expandTable ? "40%" : "50",
              bottom: expandTable ? "auto" : "0",
              left: "0%",
              transform: expandTable
                ? "translate(-50%, -50%)"
                : "translateX(-50%)",
              backgroundColor: "#364554",
              boxShadow: 3,
              width: drawerOpen ? `calc(100% - 250px)` : "100%",
              maxHeight: "100%",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <hr style={{ marginTop: "10px" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ padding: "1%" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Table Schedule
                </Typography>
              </Box>
              <Box sx={{ padding: "1%" }}>
                {expandTable ? (
                  <CloseFullscreenIcon
                    onClick={handleExpandTable}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <OpenInFullIcon
                    onClick={handleExpandTable}
                    style={{ cursor: "pointer" }}
                  />
                )}

                <CloseIcon
                  onClick={handleCloseTable}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            {expandTable ? (
              <Box
                sx={{
                  display: "flex",

                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "49%" }}>
                  <CustomTable
                    colDefs={TableOnecolDefs}
                    rowData={tableOneRowData}
                    whichPage={"TableSch"}
                    whichTable={"TableSch"}
                    getTableValue={getTableValue}
                  />
                </Box>

                <Box sx={{ width: "49%", marginRight: "1%" }}>
                  <CustomTable
                    colDefs={TableTwocolDefs}
                    rowData={tableTwoRowData}
                    whichPage={"TableSchOther"}
                  />
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Slide>
      </Box>
    </>
  );
}
