import api from "./Api";

async function GetCuttingTrack(requestBody) {
  try {
    const response = await api.post(`cutting-track/list`, requestBody);
    return response;
  } catch (error) {
    console.error("Failed to fetch cutting track:", error);
    return error;
  }
}
async function GetOptionsDetail() {
  try {
    const response = await api.get(`cutting-track/details`);
    return response;
  } catch (error) {
    console.error("Failed to fetch cutting track:", error);
    return error;
  }
}
async function GetEditTrack(Id, oeId) {
  try {
    const response = await api.get(`cutting-track/view/${Id}`, {
      params: {
        oeId: oeId,
      },
    });
    return response;
  } catch (error) {
    console.error("Failed to fetch cutting track:", error);
    return error;
  }
}
async function SubmitEditTrack(Id, data) {
  try {
    const response = await api.post(`cutting-track/update/${Id}`, {
      body: { data },
    });
    return response;
  } catch (error) {
    console.error("Failed to fetch cutting track:", error);
    return error;
  }
}
export { GetCuttingTrack, GetOptionsDetail, GetEditTrack, SubmitEditTrack };
