export const menuItemsData = [
  {
    title: "Cutting",
    url: "/",
    submenu: [
      {
        title: "Dashboard",
        url: "dashboard",
      },
      {
        title: "Lot Plan",
        url: "lot-plan",
        submenu: [
          {
            title: "Receive Fabric",
            url: "lot-plan/receive-fabric",
          },
          // {
          //   title: "Backend",
          //   submenu: [
          //     {
          //       title: "NodeJS",
          //       url: "node",
          //     },
          //     {
          //       title: "PHP",
          //       url: "php",
          //     },
          //   ],
          // },
        ],
      },
      {
        title: "Cutting Plan",
        url: "cutting-plan",
        submenu: [
          {
            title: "Drafts",
            url: "cutting-plan/drafts",
          },
        ]
      },
      {
        title: "Layer Plan",
        url: "layer-plan",
      },
      {
        title: "Floor Plan",
        url: "floor-plan",
      },
      {
        title: "Ticket Sequencing",
        url: "ticket-sequencing",
      },
      {
        title: "Cutting Track",
        url: "cutting-track",
      },
      {
        title: "Table Plan",
        url: "table-plan",
      },
      {
        title: "Issue and Recieve",
        url: "issue-and-receive",
      },
    ],
  },
  {
    title: "Stitching",
    url: "/",
  },
  {
    title: "Store Management",
    url: "/",
  },
  {
    title: "Production",
    url: "/",
  },
];
