import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomTable from "../components/common/tables/CustomTable";
import dayjs from "dayjs";
import TableAllotmentFilter from "../components/specific/tablePlan/TablePlanFilter";
import { Box } from "@mui/material";
import AlertDialogSlide from "../components/specific/tablePlan/PopupModel";
import { getTablePlan } from "../services/GetTablePlanData";
import CustomAlert from "../components/common/CustomAlert";
import * as ENUMTEXT from "../constants/AlertText";
import { getBuyers } from "../services/GetBuyer";
import { getOrderDetails } from "../services/GetOrderDetailsTablePlan";
export default function TablePlan() {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [drawerOpen, setDrawerOpen] = useState(false);
  const buyerNameOptions = ["Roman", "john", "xyz", "joseph"];
  const merchantNameOptions = ["SethRollins", "Dolph", "Ziggler", "Usos"];
  const [open, setOpen] = useState(false);
  const [filterBody, setFilterBody] = useState([]);
  const [tableData, setTableData] = useState();
  const [rowData, setRowData] = useState([]);
  const [buyers, setBuyers] = useState();
  const [contractors, setContractors] = useState();
  const [specificLayerData, setSpecificLayerData] = useState();
  const alertRef = useRef();

  const ActionCellRenderer = (props) => {
    const handleClick = async () => {
      console.log("Table Plan Data", props.node.data);
      const rowIndex = props.rowIndex;
      const colId = props.column.colId;
      const cellData = props.value;

      console.log("Table Plan Particular Cell data", {
        rowIndex,
        colId,
        visible: cellData.visible,
        id: cellData.id,
        oeId: cellData.oeId,
      });

      let OrderResponse = await getOrderDetails(cellData.id, cellData.oeId);
      if (OrderResponse.status === 200) {
        console.log("Perticular Order Data", OrderResponse);
        setSpecificLayerData(OrderResponse.data.response);
        setOpen(true);
      } else {
        alertRef.current.showAlert(OrderResponse, ENUMTEXT.ERROR.ERROR_SERV);
      }
    };
    return (
      <span
        onClick={handleClick}
        style={{ textDecoration: "underline", cursor: "pointer" }}
      >
        {props.value && props.value.visible ? props.value.visible : ""}
      </span>
    );
  };

  const getTableNumberRowSpan = (params) => {
    if (params.node.rowIndex === 0) {
      return 1;
    }

    const currentTableNumber = params.data.tableNumber;
    let rowSpan = 1;

    for (
      let i = params.node.rowIndex + 1;
      i < params.api.getDisplayedRowCount();
      i++
    ) {
      const nextRowNode = params.api.getDisplayedRowAtIndex(i);
      if (nextRowNode.data.tableNumber === currentTableNumber) {
        rowSpan++;
      } else {
        break;
      }
    }

    return rowSpan;
  };

  const generateDateColumns = (year, month) => {
    function formatWithLeadingZero(value) {
      return value < 10 ? `0${value}` : value;
    }
    const date = new Date(year, month, 1);
    const columns = [];

    while (date.getMonth() === month) {
      columns.push({
        headerName: date.toDateString(),
        field: `${formatWithLeadingZero(
          date.getDate()
        )}-${formatWithLeadingZero(
          date.getMonth() + 1
        )}-${formatWithLeadingZero(date.getFullYear())}`,
        minWidth: 160,
        cellRenderer: ActionCellRenderer,
      });
      date.setDate(date.getDate() + 1);
    }
    return columns;
  };

  useMemo(() => {
    if (selectedDate) {
      setSelectedMonth(selectedDate.month());
      setSelectedYear(selectedDate.year());
    }
  }, [selectedDate]);

  const dateColumns = useMemo(
    () => generateDateColumns(selectedYear, selectedMonth),
    [selectedYear, selectedMonth]
  );
  // const columns = useMemo(() => {
  //   return [
  //     {
  //       field: 'tableNumber',
  //       headerName: 'Table Number',
  //       width: 150,
  //       rowSpan: (params) => {
  //         const tableNumber = params.data.tableNumber;
  //         const tableMatch = tableNumber.match(/^Table (\d+)/);
  //         if (tableMatch) {
  //           const tableIndex = parseInt(tableMatch[1], 10);
  //           return params.node.rowIndex + 1 - (tableIndex - 1) * 2;
  //         }
  //         return 1;
  //       },
  //     },
  //     ...dateColumns
  //   ];
  // }, [dateColumns]);

  const rowSpan = (params) => {
    return params.data ? params.data.rowSpan : undefined;
  };

  const cellSpanList = (list) => {
    if (list === undefined) return "";
    console.log(list);
    const keys = Object.keys(list);

    // Map each key to the desired string format and join them with '||'
    const conditions = keys.map((key) => `value==='${key}'`).join("||");

    // Return the final string
    return conditions;
  };

  const columns = useMemo(() => {
    return [
      {
        field: "tableNumber",
        headerName: "Table Number",
        // rowSpan: (params) => {
        //   const tableNumber = params.data.tableNumber;
        //   if (tableNumber) {
        //     const tableMatch = tableNumber.match(/^Table (\d+)/);
        //     if (tableMatch) {
        //       const tableIndex = parseInt(tableMatch[1], 10);
        //       let rowSpan = 1;

        //       // Loop through the subsequent rows to calculate the rowSpan
        //       for (let i = params.node.rowIndex + 1; i < params.api.getDisplayedRowCount(); i++) {
        //         const nextRowNode = params.api.getDisplayedRowAtIndex(i);
        //         const nextTableNumber = nextRowNode.data.tableNumber;

        //         // Check if the next row's tableNumber matches the current row's tableIndex
        //         if (nextTableNumber) {
        //           const nextTableMatch = nextTableNumber.match(/^Table (\d+)/);
        //           if (nextTableMatch) {
        //             const nextTableIndex = parseInt(nextTableMatch[1], 10);
        //             if (nextTableIndex === tableIndex) {
        //               rowSpan++;
        //             } else {
        //               break;
        //             }
        //           }
        //         }
        //       }
        //       return rowSpan;
        //     }
        //   }
        //   return 1;
        // },
        rowSpan: rowSpan,
        cellClassRules: {
          "cell-span": cellSpanList(tableData),
        },
      },
      ...dateColumns,
    ];
  }, [dateColumns, tableData]);

  useMemo(() => {
    const getBuyerContractor = async () => {
      const response = await getBuyers();
      if (response.status === 200) {
        console.log(response.data.response.buyers);
        console.log(response.data.response.contactors);
        setBuyers(response.data.response.buyers);
        setContractors(response.data.response.contactors);
      } else {
        alertRef.current.showAlert(response, ENUMTEXT.ERROR.ERROR_SERV);
      }
    };
    getBuyerContractor();
  }, []);

  useEffect(() => {
    function formatWithLeadingZero(value) {
      return value < 10 ? `0${value}` : value;
    }
    let specifiedMonth = `${formatWithLeadingZero(
      selectedMonth + 1
    )}-${selectedYear}`;

    const updateOrAddFilter = (key, value) => {
      const index = filterBody.findIndex((item) => item.key === key);
      if (index !== -1) {
        filterBody[index].value = value;
      } else {
        filterBody.push({ key, value });
      }
    };
    updateOrAddFilter("specifiedMonth", specifiedMonth);
    let requestBody = {
      filters: filterBody,
      // "sorting": {
      //   "sortBy": "buyer",
      //   "sortOrder": "ASC"
      // },
      pageNo: 1,
      pageSize: 100,
    };
    console.log("request body", requestBody);
    const getTablePlanMainData = async () => {
      const tablePlanOrderData = await getTablePlan(requestBody);
      if (tablePlanOrderData.status === 200) {
        if (tablePlanOrderData.data.code === 200) {
          console.log("My data", tablePlanOrderData.data.response);
          setTableData(tablePlanOrderData.data.response);
        } else {
          alertRef.current.showAlert(
            ENUMTEXT.WARNING.NO_DATA_SELECTED_MONTH,
            ENUMTEXT.WARNING.WARNING_SERV
          );
          setRowData([]);
        }
      } else {
        alertRef.current.showAlert(
          tablePlanOrderData,
          ENUMTEXT.ERROR.ERROR_SERV
        );
      }
    };
    getTablePlanMainData();
  }, [selectedDate, filterBody]);

  useMemo(() => {
    console.log("Data in use Memo", tableData);
    if (tableData) {
      const transformData = (response) => {
        const transformedData = [];
        const seenTableNumbers = new Set();

        Object.keys(response).forEach((tableKey) => {
          const specificTableData = response[tableKey];
          Object.keys(specificTableData).forEach((specificDate) => {
            specificTableData[specificDate].forEach((order) => {
              const content = `${order.orderId} (${order.layerName})`;

              // Check if an object with the same tableNumber already exists
              let existingObject = transformedData.find(
                (obj) => obj.tableNumber === tableKey && !obj[specificDate]
              );

              // If no suitable object found, create a new one
              const datesList = Object.keys(specificTableData);
              let rowLength = 0;
              for (let i = 0; i < datesList.length; i++) {
                const length = Object.keys(
                  tableData[tableKey][datesList[i]]
                ).length;
                if (length > rowLength) {
                  rowLength = length;
                }
              }
              if (!existingObject) {
                existingObject = {
                  tableNumber: seenTableNumbers.has(tableKey) ? "" : tableKey,
                  tnumber: tableKey,
                  rowSpan: seenTableNumbers.has(tableKey) ? 0 : rowLength,
                };
                transformedData.push(existingObject);
                seenTableNumbers.add(tableKey);
              }

              // Add the date and content to the object
              existingObject[specificDate] = {
                visible: content,
                id: order.id,
                oeId: order.oeId,
              };
            });
          });
        });

        console.log("Transformed Data:", transformedData);
        return transformedData;
      };

      setRowData(transformData(tableData));
    }
  }, [tableData]);

  let leftFields = {};
  let rightFields = {};

  useMemo(() => {
    if (specificLayerData) {
      // Populate leftFields only with non-empty values
      Object.entries({
        "Order Number": specificLayerData.orderId,
        "Buyer Name": specificLayerData.buyerName,
        "Merchant Name": specificLayerData.merchantName,
        "Style Detail": specificLayerData.style,
        Color: specificLayerData.color,
        "Layer Number": specificLayerData.layerName,
        "Size Combination": specificLayerData.sizeCombination,
        "Ply Quantity": specificLayerData.plyQty,
        "Table Number": specificLayerData.tableMaster.tableNumber,
        "Re-cutting Applied":
          specificLayerData.reCuttingApplied === true ? "Yes" : "No",
        "Fabric Name": specificLayerData.fabricName,
        "Fabric Quality": specificLayerData.fabricQuality,
        "Fabric Width": specificLayerData.fabricWidth,
        "Available Fabric": specificLayerData.avlFabric,
        "Consumed Fabric": specificLayerData.consumedFabric,
        "Demanded Quantity": specificLayerData.quantity,
      }).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== "") {
          leftFields[key] = value;
        }
      });

      // Add demanded sizes if they exist
      Object.keys(specificLayerData.demandedQuantityBySizes).forEach((size) => {
        const value = specificLayerData.demandedQuantityBySizes[size];
        if (value !== undefined && value !== null && value !== "") {
          leftFields[size] = value;
        }
      });

      // Populate rightFields only with non-empty values
      Object.entries({
        "Add On Quantity": "50",
        "Maximum Number of Ply": specificLayerData.maxNoOfPly,
        "Number of Lots": specificLayerData.numberOfLots,
        "Merchant Average": specificLayerData.merchantAvg,
        "Planned Average": specificLayerData.plannedAvg,
        "PP Approval": specificLayerData.ppApproval,
        "Bulk Inhouse": specificLayerData.bulkInHouse,
        "Delivery Date": specificLayerData.deliveryDate,
        "Cutting Start Date": specificLayerData.cuttingStartDate,
        "Cutting End Date": specificLayerData.cuttingEndDate,
        "Stitching Start Date": specificLayerData.stitchingStartDate,
        "Stitching End Date": specificLayerData.stitchingEndDate,
        "Scheduled Cutting Date": specificLayerData.allotmentDate,
        Line: specificLayerData.line,
        "Size Set": specificLayerData.sizeSet,
        Embroidery: specificLayerData.embroidery,
        "Total No. of Parts": specificLayerData.totalNoOfParts,
        "Fusing Parts": specificLayerData.fusingParts,
        "Hand Cutting Parts": specificLayerData.handCuttingParts,
        "Re-cutting Parts": specificLayerData.reCuttingParts,
        Pinning: specificLayerData.pinning === true ? "Yes" : "No",
      }).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== "") {
          rightFields[key] = value;
        }
      });
    }
  }, [specificLayerData]);

  return (
    <>
      <CustomAlert ref={alertRef} />
      <AlertDialogSlide
        open={open}
        setOpen={setOpen}
        leftFields={leftFields}
        rightFields={rightFields}
      ></AlertDialogSlide>
      <TableAllotmentFilter
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        buyerNameOptions={buyers}
        merchantNameOptions={contractors}
        filterBody={filterBody}
        setFilterBody={setFilterBody}
      />
      <Box sx={{ width: drawerOpen ? `calc(100% - 280px)` : "100%" }}>
        <CustomTable
          rowData={rowData}
          colDefs={columns}
          whichPage={"TablePlan"}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setDrawerOpen={setDrawerOpen}
        />
      </Box>
    </>
  );
}
