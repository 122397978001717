import * as React from "react";
import {
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  OutlinedInput,
  MenuProps,
} from "@mui/material";

export default function SelectDropdown({ onChange, Options, label, value }) {
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        overflowY: "auto",
      },
    },
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        value={value}
        MenuProps={menuProps}
      >
        {Options.map((option) => (
          <MenuItem key={option.id} value={option.tableNumber || option.name}>
            {option.tableNumber ? option.tableNumber : option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
