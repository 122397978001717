import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Box,
    Grid,
    IconButton
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchOrders } from "../../services/OrderListServices";
import { getAllDrafts } from "../../services/CuttingPlan";
import sortOrderIds from "../../components/common/SortOrders";
import CustomButton from "../../components/common/buttons/CustomButton";
import CustomAutoComplete from "../../components/common/dropdowns/CustomAutoComplete";
import ResponsiveDialog from "../../components/common/NoStyleModel";
import OrderDetailModel from "../../components/specific/lotplan/OrderDetailsModel";
import DraftCustomTable from "../../components/specific/draft/DraftTableAg";
import EditIcon from "@mui/icons-material/Edit";
import CustomAlert from "../../components/common/CustomAlert";
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as ENUMTEXT from "../../constants/AlertText";


export default function CompareDrafts() {
    const alertRef = useRef();
    const location = useLocation();
    const { orderInformation } = location.state;
    const [orderData, setOrderData] = useState([]);
    const [orderOptions, setOrderOptions] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [orderDetailData, setOrderDetailData] = useState({});
    const orderInfoRef = useRef({
        OrderNumberValue: null,
        buyerName: "",
        merchantName: "",
        optionsStyle: [],
        Stylevalue: null,
        fabricName: "",
        fabricQuality: "",
        maxNoPly: "",
    });
    const OrderNumberInputValue = useRef();
    const StyleInputValue = useRef();
    const [orderInfo, setOrderInfo] = useState(orderInfoRef.current);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [rowData, setRowData] = useState([]);
    const initialDataLoaded = useRef(false);

    const ActionCellRenderer = (props) => {
        const handleEditDraftNavigate = () => {
            let draftId = props.node.data.draftID;
            navigate("/cutting-plan/drafts/edit", {
                state: {
                    orderInformation: orderInfo, draftId: Number(draftId
                    )
                }
            });
        };
        const handleViewDraftNavigate = () => {
            let indexNo = props.node.id;
            navigate("/cutting-plan/drafts", { state: { orderInformation: orderInfo, draftId: Number(indexNo) } });
        }

        const handleFreezeDraftNavigate = () => {
            let indexNo = props.node.id;
            navigate("/cutting-plan/drafts", { state: { orderInformation: orderInfo, draftId: Number(indexNo), freeze: true } });
        }
        return (
            <>
                <IconButton onClick={handleFreezeDraftNavigate}>
                    <DriveFileMoveIcon />
                </IconButton>
                <IconButton onClick={handleViewDraftNavigate}>
                    <VisibilityIcon />
                </IconButton>
                <IconButton onClick={handleEditDraftNavigate}>
                    <EditIcon />
                </IconButton>
            </>
        );
    };

    const [colDefs, setColDefs] = useState([
        {
            field: "draftNumber",
        },
        {
            field: "layerAverage"
        },
        {
            headerName: "Avg. Layer Length",
            field: "avgLayerLength"
        },
        { field: "availableFabric" },
        { field: "fabricConsumption", minWidth: 150 },
        { field: "remainingFabric" },
        {
            headerName: "Total No. of Ply",
            field: "totalPly"
        },
        { field: "noOfLayersInvolved", minWidth: 150, },
        { field: "piecesCut" },
        { field: "piecesRemaining" },
        { field: "averageEfficiency" },
        {
            field: "action",
            minWidth: 180,
            cellRenderer: ActionCellRenderer,
        },

    ]);

    // const handleOrderChange = useCallback((event, newValue) => {
    //     orderInfoRef.current.OrderNumberValue = newValue;
    //     orderInfoRef.current.Stylevalue = null;
    //     setOrderInfo({ ...orderInfoRef.current });
    //     setRowData([]);
    //     setBottomRowData([]);
    //     setOrderDetailData({});
    //     setDrawerOpen(false);
    //     isLotAvilable.current = false;
    //     const updateColumnDefs = (prevCols) => {
    //         let newColumnDefs = [...colDefs];
    //         newColumnDefs.forEach(colDef => {
    //             if (colDef.field === "total") {
    //                 colDef.children.forEach(child => {
    //                     if (child.headerName === child.headerName) {
    //                         child.headerName = "0";
    //                     }
    //                     child.children.forEach(grandchild => {
    //                         if (grandchild.field === 'lotTotal') {
    //                             grandchild.headerName = "0";
    //                         }
    //                     });
    //                 });
    //             }
    //         });
    //         return newColumnDefs
    //     };
    //     setColDefs((prevCols) =>
    //         updateColumnDefs(prevCols)
    //     );
    // }, []);


    const handleOrderChange = useCallback((event, newValue) => {
        orderInfoRef.current.OrderNumberValue = newValue;
        orderInfoRef.current.Stylevalue = null;
        setOrderInfo({ ...orderInfoRef.current });
        setRowData([]);
        setOrderDetailData({});
        setDrawerOpen(false);
    }, []);

    const handleStyleChange = useCallback((event, newValue) => {
        orderInfoRef.current.Stylevalue = newValue;
        setOrderInfo({ ...orderInfoRef.current });
        setRowData([]);
        setOrderDetailData({
            orderNumber: orderInfoRef.current.OrderNumberValue,
            buyerName: orderInfoRef.current.buyerName,
            merchantName: orderInfoRef.current.merchantName,
            orderImage: orderInfoRef.current.orderImage || "",
        });
    }, []);

    useEffect(() => {
        const loadOrders = async () => {
            try {
                const orderAllData = await fetchOrders();
                if (orderAllData.status === 200) {
                    setOrderData(orderAllData.data.response);
                    const orderIds = Object.keys(orderAllData.data.response.orders);
                    const sortedOrderIds = sortOrderIds(orderIds);
                    setOrderOptions(sortedOrderIds);
                } else {
                    alertRef.current.showAlert(orderAllData, ENUMTEXT.ERROR.ERROR_SERV);
                }
            } catch (error) {
                console.error(error);
            }
        };
        loadOrders();
    }, []);

    useEffect(() => {
        if (orderInfo.OrderNumberValue !== undefined && Object.keys(orderData?.orders ?? {}).length > 0)
            if (orderInfo.OrderNumberValue) {
                const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
                if (selectedOrder) {
                    orderInfoRef.current.buyerName = selectedOrder[0]?.buyer || "";
                    orderInfoRef.current.merchantName = selectedOrder[0]?.merchant || "";
                    orderInfoRef.current.optionsStyle = selectedOrder.map((order) => ({
                        label: `${order.style ?? ""} ${order.color ?? ""} ${order.size ?? ""} ${order.remarks ?? ""}`,
                        oeid: order.oeId ?? "",
                        sizeWithQty: order.sizeWithQty
                    }));
                    if (orderInfoRef.current.optionsStyle.length === 1) {
                        orderInfoRef.current.Stylevalue = orderInfoRef.current.optionsStyle[0];
                    }
                    setOrderInfo({ ...orderInfoRef.current });
                    setOrderDetailData({
                        orderNumber: orderInfoRef.current.OrderNumberValue,
                        buyerName: orderInfoRef.current.buyerName,
                        merchantName: orderInfoRef.current.merchantName,
                        orderImage: selectedOrder[0]?.imagePath || "",
                    });
                    setDrawerOpen(true);
                }
            } else {
                orderInfoRef.current.buyerName = "";
                orderInfoRef.current.merchantName = "";
                orderInfoRef.current.optionsStyle = [];
                setOrderInfo({ ...orderInfoRef.current });
            }
    }, [orderInfo.OrderNumberValue, orderData]);


    useEffect(() => {
        const setOrderInfoData = async () => {
            if (!initialDataLoaded.current && orderInformation.OrderNumberValue && orderInformation.Stylevalue) {
                orderInfoRef.current.OrderNumberValue = orderInformation.OrderNumberValue;
                orderInfoRef.current.Stylevalue = orderInformation.Stylevalue;
                orderInfoRef.current.buyerName = orderInformation.buyerName;
                orderInfoRef.current.fabricName = orderInformation.fabricName;
                orderInfoRef.current.fabricQuality = orderInformation.fabricQuality;
                orderInfoRef.current.maxNoPly = orderInformation.maxNoPly;
                orderInfoRef.current.merchantName = orderInformation.merchantName;
                orderInfoRef.current.optionsStyle = orderInformation.optionsStyle;
                orderInfoRef.current.avlFabric = orderInformation.avlFabric;
                setOrderInfo(
                    { ...orderInfoRef.current }
                );
                initialDataLoaded.current = true;
            }
        };
        setOrderInfoData();
    }, [orderInfo.OrderNumberValue, orderInfo.Stylevalue]);


    useEffect(() => {
        if (orderInfo.Stylevalue !== undefined && orderInfo.OrderNumberValue !== undefined && Object.keys(orderData?.orders ?? {}).length > 0) {
            if (orderInfo.Stylevalue && orderInfo.OrderNumberValue) {
                const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
                if (selectedOrder) {
                    const orderDetail = selectedOrder.find(
                        (order) => order.oeId === orderInfo.Stylevalue.oeid
                    );
                    if (orderDetail) {
                        if (!orderDetail.fabAvl) {
                            setDialogOpen(true);
                        } else {
                            orderInfoRef.current.fabricName = orderDetail.fabricName;
                            orderInfoRef.current.fabricQuality = orderDetail.fabricQuality;
                            orderInfoRef.current.maxNoPly = orderDetail.maxNoOfPly;
                            setOrderInfo({ ...orderInfoRef.current });
                            setOrderDetailData((prevData) => ({
                                ...prevData,
                                style: orderDetail.style,
                                color: orderDetail.color,
                                fabricName: orderDetail.fabricName,
                                fabricQuality: orderDetail.fabricQuality,
                                fabricWidth: orderDetail.fabricWidth,
                                availableFabric: orderDetail.avlFabric,
                                consumedFabric: orderDetail.consumedFabric,
                                remainingFabric: orderDetail.remainingFabric,
                                maxNoOfPly: orderDetail.maxNoOfPly,
                                fabricInhouse: orderDetail.fabInHouse,
                                ppApproval: orderDetail.ppApproval,
                                cuttingStart: orderDetail.cuttingStartDate,
                                cuttingEnd: orderDetail.cuttingEndDate,
                                stitchingStart: orderDetail.stitchingStartDate,
                                stitchingEnd: orderDetail.stitichingEndDate,
                                deliveryDate: orderDetail.deliveryDate,
                                sizeWithQty: orderDetail.sizeWithQty,
                                sumOfDemandedQty: orderDetail.sumOfDemandedQty,
                                noOfLots: orderDetail.noOfLots,
                                merchantAvg: orderDetail.merchantAvg,
                                plannedAvg: orderDetail.plannedAvg,
                                sumOfAllowedQty: orderDetail.sumOfAllowedQty,
                                sizeWithAllowedQty: orderDetail.sizeWithAllowedQty,
                                fabricWidthDetails: orderDetail.fabricWidthDetails,
                                addOnQuantity: orderDetail.addOnQuantity,
                                totalNoOfParts: orderDetail.totalNoOfParts,
                                fusingParts: orderDetail.fusingParts,
                                handCuttingParts: orderDetail.handCuttingParts,
                                reCuttingParts: orderDetail.reCuttingParts,
                                pinning: orderDetail.pinning,
                            }));
                            const getDrafts = async () => {
                                try {
                                    let draftResponse = await getAllDrafts(orderInfo.Stylevalue.oeid);
                                    if (draftResponse.status === 200) {
                                        if (draftResponse.data.response.length === 0) {
                                            alertRef.current.showAlert(ENUMTEXT.WARNING.NO_DRAFT_FOUND, ENUMTEXT.WARNING.WARNING_SERV);
                                        } else {
                                            const transformedData = draftResponse.data.response.map(order => ({
                                                draftID: order.id,
                                                draftNumber: order.draftName,
                                                layerAverage: order.layerAvg,
                                                availableFabric: order.avlFabric,
                                                fabricConsumption: order.totalFabricConsumed,
                                                remainingFabric: order.remainingFabric < 0 ? 0 : order.remainingFabric,
                                                noOfLayersInvolved: order.noOfLayersInvolved,
                                                piecesCut: order.piecesCut,
                                                piecesRemaining: order.piecesRemaining,
                                                averageEfficiency: order.totalEfficiency,
                                                avgLayerLength: order.avgLayerLength,
                                                totalPly: order.totalPlyQty
                                            }));
                                            setRowData(transformedData);
                                        }
                                    } else {
                                        alertRef.current.showAlert(draftResponse, ENUMTEXT.ERROR.ERROR_SERV);
                                    }
                                }
                                catch (e) {

                                }
                            };
                            getDrafts();
                        }
                    }
                }
            } else {
                orderInfoRef.current.fabricName = "";
                orderInfoRef.current.fabricQuality = "";
                orderInfoRef.current.maxNoPly = "";
                setOrderInfo({ ...orderInfoRef.current });
            }
        }
    }, [orderInfo.Stylevalue, orderData, orderInfo.OrderNumberValue]);

    const navigate = useNavigate();

    const handleAddDraftNavigate = () => {
        navigate("/cutting-plan/drafts/add", { state: { orderInformation: orderInfo } });
    };

    return (
        <>
            <CustomAlert ref={alertRef} />
            <Box>
                <OrderDetailModel data={orderDetailData} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            </Box>
            <ResponsiveDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            />
            <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : '100%' }}>
                <Grid container spacing={1} style={{ padding: "10px" }}>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <CustomAutoComplete
                            value={orderInfo.OrderNumberValue}
                            onChange={handleOrderChange}
                            options={orderOptions}
                            label="Select Order Number"
                            ref={OrderNumberInputValue}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={3}
                        xl={3}
                    >
                        <CustomAutoComplete
                            value={orderInfo.Stylevalue}
                            onChange={handleStyleChange}
                            options={orderInfo.optionsStyle}
                            label="Select Style Details"
                            ref={StyleInputValue}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={3}
                        xl={3}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Box style={{ marginRight: "15px" }}>
                            <CustomButton
                                variant="contained"
                                sx={{ height: "max-content" }}
                                onClick={handleAddDraftNavigate}
                            >
                                Add Drafts
                            </CustomButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <DraftCustomTable colDefs={colDefs} drawerOpen={drawerOpen} rowData={rowData} whichPage={"comparePlan"} />
            </Box>

        </>
    );
}
